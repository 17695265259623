import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './index.css';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';

let googleAuth;

if(localStorage.getItem('token')) {
  const token = JSON.parse(localStorage.getItem('token'));
  if(token.expires_at && token.expires_at > new Date().getTime()) {
    googleAuth = {
      token,
      user: JSON.parse(localStorage.getItem('profile'))
    };
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
  }
}

const store = configureStore({
  googleAuth
});

ReactDOM.render(
    <Provider store={store}>
      <Root />
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
