import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { envChange } from '../../redux/actions/env';
import './Environment.css'
export class Environment extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    environment: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.envChangeHandler = this.envChangeHandler.bind(this);
  }

  envChangeHandler(event) {
    const { dispatch } = this.props;
    dispatch(envChange(event.target.value));
  }

  render() {
    const { environment } = this.props;

    return (
      <FormControl color="primary" className={'formControl'}>
        <Select
          value={environment.name}
          onChange={this.envChangeHandler}
          inputProps={{
            name: 'env',
            id: 'env-helper',
          }}
        >
          <MenuItem value={'Dev'}>Test Environment</MenuItem>
          <MenuItem value={'Prod'}>Production</MenuItem>
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = state => ({
  environment: state.environment
});


export default connect(mapStateToProps)(Environment);
