import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default function (ComposedComponent) {
  return (props) => {
    const token = useSelector(state => state.googleAuth.token);
    return (
      <div>
        { !!token ? <ComposedComponent {...props} /> : <Redirect to="/google-sign-in" /> }
      </div>
    );
  }
}
