import * as ActionTypes from '../../constants/ActionTypes';
import { LOCATE_INV_DEV, LOCATE_INV_PROD } from '../../constants/env';

const initialState = {
  name: process.env.NODE_ENV === 'development' ? LOCATE_INV_DEV.name : LOCATE_INV_PROD.name,
  url: process.env.NODE_ENV === 'development' ? LOCATE_INV_DEV.url : LOCATE_INV_PROD.url,
};

const actionsMap = {
  [ActionTypes.ENV_CHANGE](state, payload) {
    const envrmnt = [LOCATE_INV_DEV, LOCATE_INV_PROD].find(e => e.name === payload.env);
    return Object.assign({}, state, {
      name: envrmnt.name,
      url: envrmnt.url
    });
  }
};

export default function environment(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
}
