import React from 'react';
import {BrowserRouter as Router, Redirect, Route} from 'react-router-dom';
import GoogleSignIn from './App/components/GoogleSignIn';
import requiresAuth from './App/components/requiresAuth';
import App from './App';

import './App.css';

function Root() {
  const { location } = window;
  return (
    <div className="App">
      <Router>
        <Route path="/">
          <Route path="/google-sign-in">
            <GoogleSignIn />
          </Route>
          <Route path="/app" component={requiresAuth(App)} />
        </Route>
        {location.pathname === '/' && <Redirect to={'/app'}/>}
      </Router>
    </div>
  );
}

export default Root;
