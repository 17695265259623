import React, {useEffect, useState} from 'react';
import './UoToCreateModal.css';
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
  locateInvCreateTO1,
  locateInvCreateTO1Line,
  locateInvCreateTO2,
  locateInvCreateTO2Line,
  locateInvCreateTO3,
  locateInvCreateTO3Line,
  locateInvCreateUO,
  locateInvCreateUOLine,
  locateInvIssueTO1,
  locateInvIssueTO2, locateInvIssueTO3,
  locateInvIssueUO
} from "../../../redux/actions/locateInv";
import CreateToStatus from "./CreateToStatus";

const UoToCreateModal = ({site, open, xeroProject, handleCloseMain, products, selectedLocations, environment}) => {

  const dispatch = useDispatch();
  const {
    createUo, createUoList, createUoIssue,
    createTo1, createTo1List, createTo1Issue,
    createTo2, createTo2List, createTo2Issue,
    createTo3, createTo3List, createTo3Issue
  } = useSelector(state => state.locateInv);
  const {url} = environment;

  const {TOLocation1, TOLocation2, TOLocation3} = selectedLocations;
  const [UO, setUO] = useState([]);
  const [TO1, setTO1] = useState([]);
  const [TO2, setTO2] = useState([]);
  const [TO3, setTO3] = useState([]);
  const [disabledCreateButtons,setDisabledCreateButtons] = useState(false);

  const handleClose = () => {
    // console.log('UU:',UO);
    // console.log('UU:',TO1);
    // console.log('UU:',TO2);
    // console.log('UU:',TO3);
    // setUO([]);
    // setTO1([]);
    // setTO2([]);
    // setTO3([]);
    handleCloseMain();
  }

  const createUoHandler = () => {
    dispatch(locateInvCreateUO(site.id, xeroProject.name));
  }

  const issueUoHandler = () => {
    dispatch(locateInvIssueUO(createUo.order.id))
  }

  const createTo1Handler = () => {
    dispatch(locateInvCreateTO1(site.id,TOLocation1.id))
  }

  const createTo2Handler = () => {
    dispatch(locateInvCreateTO2(site.id,TOLocation2.id))
  }

  const createTo3Handler = () => {
    dispatch(locateInvCreateTO3(site.id,TOLocation3.id))
  }

  useEffect(()=>{
    if(createUo.loading || createUoList.loading || createUoIssue.loading || createTo1.loading || createTo1List.loading || createTo1Issue.loading || createTo2.loading || createTo2List.loading || createTo2Issue.loading || createTo3.loading || createTo3List.loading || createTo3Issue.loading ) {
      setDisabledCreateButtons(true);
    } else {
      setDisabledCreateButtons(false);
    }
  },[createUo.loading, createUoList.loading,createUoIssue.loading, createTo1.loading, createTo1List.loading, createTo1Issue.loading,createTo2.loading, createTo2List.loading, createTo2Issue.loading,createTo3.loading, createTo3List.loading, createTo3Issue.loading])

  useEffect(()=>{
    if(createUo.order && !createUoList.order && !createUoList.error) {
      dispatch(locateInvCreateUOLine(createUo.order.id,products.map(el=> ({location_id:el.location_id,part_id:el.part_id,qty:el.UoToQty.uoQty}))));
    }
  },[createUo.order])

  useEffect(()=>{
    if(createTo1.order && !createTo1List.order && !createTo1List.error) {
      dispatch(locateInvCreateTO1Line(createTo1.order.id,products.map(el=> ({part_id:el.part_id,qty:el.UoToQty.toQtyL1}))));
    }
    if(createTo1List.order && !createTo1Issue.order && !createTo1Issue.error) {
      dispatch(locateInvIssueTO1(createTo1.order.id));
    }
  },[createTo1.order,createTo1List.order])

  useEffect(()=>{
    if(createTo2.order && !createTo2List.order && !createTo2List.error) {
      dispatch(locateInvCreateTO2Line(createTo2.order.id,products.map(el=> ({part_id:el.part_id,qty:el.UoToQty.toQtyL2}))));
    }
    if(createTo2List.order && !createTo2Issue.order && !createTo2Issue.error) {
      dispatch(locateInvIssueTO2(createTo2.order.id));
    }
  },[createTo2.order,createTo2List.order])

  useEffect(()=>{
    if(createTo3.order && !createTo3List.order && !createTo3List.error) {
      dispatch(locateInvCreateTO3Line(createTo3.order.id,products.map(el=> ({part_id:el.part_id,qty:el.UoToQty.toQtyL3}))));
    }
    if(createTo3List.order && !createTo3Issue.order && !createTo3Issue.error) {
      dispatch(locateInvIssueTO3(createTo3.order.id));
    }
  },[createTo3.order,createTo3List.order])

  useEffect(() => {
    setUO(products.filter(el => el.UoToQty?.uoQty > 0));
    setTO1(products.filter(el => el.UoToQty?.toQtyL1 > 0));
    setTO2(products.filter(el => el.UoToQty?.toQtyL2 > 0));
    setTO3(products.filter(el => el.UoToQty?.toQtyL3 > 0));
  }, [products])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <Grid container style={{textAlign: "center"}}>
        <Grid item xs={10}>
          <p style={{fontSize: '1rem'}}>Creating UOs and TOs for Vendor Site:<br/> <b
            style={{fontSize: '1.2rem'}}>{xeroProject?.name}</b></p>
        </Grid>
        <Grid item xs={2} style={{height: 50, padding: "20px 5px"}}>
          <Button style={{margin: "auto"}} onClick={handleClose} variant="contained" color="primary">Close</Button>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container>

          {xeroProject && UO.length > 0 &&
          <Grid item xs={12} style={{margin: '20px 0'}}>
            <h2 style={{marginBlockEnd: 0}}>Use Order</h2>
            <p style={{marginBlockStart: 0}}>Xero Project Name: <b>{xeroProject?.name}</b></p>
            <TableContainer component={Paper}>
              <Table className='' size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell><b>Product Number</b></TableCell>
                    <TableCell><b>Product Name</b></TableCell>
                    <TableCell align={"center"} width={100}><b>Qty to UO</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {UO.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer"
                           target={'_blank'}> {el.part.number}</a>
                      </TableCell>
                      <TableCell>
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer"
                           target={'_blank'}> {el.part.name}</a>
                      </TableCell>
                      <TableCell align="center">{el.UoToQty?.uoQty}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{display: 'flex'}}>
              <div style={{width: 150}}>
                {(createUo.loading || createUoList.loading || createUoIssue.loading || (createUoList.order && !createUoIssue.order)) ?
                  <Button disabled color="secondary" variant="contained">In Progress</Button>
                  :
                  !createUoIssue.order ?
                    <Button disabled={disabledCreateButtons} onClick={createUoHandler} variant="contained" color="secondary">Create UO</Button>
                    :
                    <Button disabled color="secondary" variant="contained">Complete</Button>
                }
              </div>
              {(createUo.loading || createUo.order || createUo.error) &&
              <div>
                <b>Status:</b><br/>
                <ol>
                  {(createUo.loading || createUo.order || createUo.error) &&
                  <li>
                    <span>Creating UO ….</span>
                    {createUo.order && <span><b style={{color: 'green'}}>Success!</b> Here is the UO:<a  href={`${url}/app#/useorder/${createUo.order.id}/`} rel="noopener noreferrer" target={'_blank'}>UO #{createUo.order.number}</a></span>}
                    {createUo.error && <span><b style={{color: 'red'}}>Error!</b></span>}
                  </li>
                  }
                  {(createUoList.loading || createUoList.order || createUoList.error) &&
                  <li>
                    <span>Adding Line Items ….</span>
                    {createUoList.order && <span><b style={{color: 'green'}}>Success!</b></span>}
                    {createUoList.error && <span><b style={{color: 'red'}}>Error!</b></span>}
                  </li>
                  }
                  {createUoList.order &&
                  <li>Last step, and your last chance to check your work. Remember, UOs are final - they can’t be
                    undone. Should I issue this UO?<br />
                    {!createUoIssue.order && !createUoIssue.loading &&
                    <Button onClick={issueUoHandler} variant="contained" color="secondary">Issue UO</Button>
                    }
                  </li>}
                  {(createUoIssue.loading || createUoIssue.order || createUoIssue.error) &&
                  <li>
                    <span>Issuing UO ….</span>
                    {createUoIssue.order && <span><b style={{color: 'green'}}>Success!</b> Your UO has been issued. Locate should autocomplete in a few moments. Check and make sure that has happened: <a  href={`${url}/app#/useorder/${createUo.order.id}/`} rel="noopener noreferrer" target={'_blank'}>UO #{createUo.order.number}</a></span>}
                    {createUoIssue.error && <span><b style={{color: 'red'}}>Error!</b></span>}
                  </li>
                  }
                </ol>
                {createUo.error && <b>Try again, or ask someone for help if you keep getting this error (send them the error shown above).</b>}
                {createUoList.error && <b>Before clicking “Create UO” again, delete the UO above in Locate. If you get this error more than once, ask someone for help.</b>}
                {createUoIssue.error && <b>Try opening the UO in Locate and issuing it yourself.<br />Before clicking “Create UO” again, delete the UO above in Locate. If you get this error more than once, ask someone for help.</b>}
              </div>
              }
            </div>
          </Grid>}

          {TO1.length > 0 &&
          <Grid item xs={12} style={{margin: '20px 0'}}>
            <h2 style={{marginBlockEnd: 0}}>Transfer Order 1</h2>
            <p style={{marginBlockStart: 0}}>To location: <b>{TOLocation1?.name}</b></p>
            <TableContainer component={Paper}>
              <Table className='' size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Number</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell align={"center"} width={100}>Qty to TO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TO1.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer" target={'_blank'}> {el.part.number}</a>
                      </TableCell>
                      <TableCell>
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer" target={'_blank'}> {el.part.name}</a>
                      </TableCell>
                      <TableCell align="center">{el.UoToQty?.toQtyL1}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <CreateToStatus
              url={url}
              createTo={createTo1}
              createToList={createTo1List}
              createToIssue={createTo1Issue}
              createToHandler={createTo1Handler}
              disabledCreateButtons={disabledCreateButtons}
            />

          </Grid>}

          {TO2.length > 0 &&
          <Grid item xs={12} style={{margin: '20px 0'}}>
            <h2 style={{marginBlockEnd: 0}}>Transfer Order 2</h2>
            <p style={{marginBlockStart: 0}}>To location: <b>{TOLocation2?.name}</b></p>
            <TableContainer component={Paper}>
              <Table className='' size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Number</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell align={"center"} width={100}>Qty to TO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TO2.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer" target={'_blank'}> {el.part.number}</a>
                      </TableCell>
                      <TableCell>
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer" target={'_blank'}> {el.part.name}</a>
                      </TableCell>
                      <TableCell align="center">{el.UoToQty?.toQtyL2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <CreateToStatus
              url={url}
              createTo={createTo2}
              createToList={createTo2List}
              createToIssue={createTo2Issue}
              createToHandler={createTo2Handler}
              disabledCreateButtons={disabledCreateButtons}
            />

          </Grid>}

          {TO3.length > 0 &&
          <Grid item xs={12} style={{margin: '20px 0'}}>
            <h2 style={{marginBlockEnd: 0}}>Transfer Order 3</h2>
            <p style={{marginBlockStart: 0}}>To location: <b>{TOLocation3?.name}</b></p>
            <TableContainer component={Paper}>
              <Table className='' size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product Number</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell align={"center"} width={100}>Qty to TO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TO3.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer" target={'_blank'}> {el.part.number}</a>
                      </TableCell>
                      <TableCell>
                        <a href={`${url}/app#/part/${el.part.id}/`} rel="noopener noreferrer" target={'_blank'}> {el.part.name}</a>
                      </TableCell>
                      <TableCell align="center">{el.UoToQty?.toQtyL3}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <CreateToStatus
              url={url}
              createTo={createTo3}
              createToList={createTo3List}
              createToIssue={createTo3Issue}
              createToHandler={createTo3Handler}
              disabledCreateButtons={disabledCreateButtons}
            />

          </Grid>}
        </Grid>
      </DialogContent>
    </Dialog>);
}

export default UoToCreateModal;
