import React from 'react';
import {TableContainer,Table,TableHead,TableRow,TableCell,TableBody, Paper} from '@material-ui/core';
import {useSelector} from "react-redux";
import RowCalculated from "./RowCalculate";

const SiteInventoryTool = ({products, setProducts, locationVisibility, xeroProject}) => {
  const {environment} = useSelector(state=>state)
  const url = environment.url

  const rowHandler = (UoToQty, index) => {
    let isEmptyUoToQty = !Object.values(UoToQty).filter(v=>v>0).length>0
    const newProducts = [...products]

    if(isEmptyUoToQty) {
      if(newProducts[index].UoToQty) delete newProducts[index].UoToQty
    } else {
      newProducts[index] = {...products[index], UoToQty}
    }

    setProducts(newProducts)
  }

  return (
    <div>
    <TableContainer component={Paper}>
      <Table className='' size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Product Number</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell align={"center"}>Inventory at Site</TableCell>
            <TableCell align={"center"} width={100}>Qty to UO</TableCell>
            {locationVisibility.l1 && <TableCell align={"center"} width={100}>Qty to TO to Location 1</TableCell>}
            {locationVisibility.l2 && <TableCell align={"center"} width={100}>Qty to TO to Location 2</TableCell>}
            {locationVisibility.l3 && <TableCell align={"center"} width={100}>Qty to TO to Location 3</TableCell>}
            <TableCell align={"center"} width={140}>Remaining Inventory  After UOs/TOs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((row, index) => (
            <RowCalculated key={`id-${index}`} rowHandler={rowHandler} uoDisabled={xeroProject===false} locationVisibility={locationVisibility} row={row} index={index} url={url} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

export default SiteInventoryTool
