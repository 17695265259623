import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import MaskedInput from 'react-text-mask';
import MuiAlert from '@material-ui/lab/Alert';

import UnlinkedProjects from './UnlinkedProjects';
import ProjectsDataTable from './ProjectsDataTable';
import LinkDialog from './LinkDialog';

import {
  xeroProjectCreateInitActionCreator,
  xeroProjectCreateInitializeActionCreator,
} from '../../redux/actions/xero';
import {
  fetchEnterpriseProjectDataActionCreator,
  createVendorAndVendorSiteLinkWithXero,
  linkVendorSiteToTrackingCategory
} from '../../redux/actions/locateInv';

import { optionGet, optionSet } from '../../redux/actions/options';
import { statesArr } from "../../constants/settings";
import Switch from "@material-ui/core/Switch";
import {Helmet} from "react-helmet";

const MAX_NAME_LENGTH = 21;

function Alert(props) {
  return <MuiAlert elevation={6} style={{padding: '20px'}} variant="filled" {...props} />;
}

class App extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    googleAuth: PropTypes.object.isRequired,
    environment: PropTypes.object.isRequired,
    xeroLocate: PropTypes.object.isRequired,
    locateInv: PropTypes.object.isRequired,
    proxy: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);
    this.state = {
      projectName: '',
      clientName: '',
      projectAddr: '',
      projectState: statesArr[0],
      projectType: 'C',
      projectDate: '',
      sitesFiltered: [],
      tcFiltered: [],
      dialogOpen: false,
      selectedXeroProject: null,
      skipXeroCreate: false,
      selectedSite: null,
      vendorName: '',
      vendorNameError: false,
      showHiddenTable: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleSiteSelect = this.handleSiteSelect.bind(this);
    this.handleVendorNameChange = this.handleVendorNameChange.bind(this);
    this.handleCreateProject = this.handleCreateProject.bind(this);
    this.handleCreateVendorWithSite = this.handleCreateVendorWithSite.bind(this);
    this.handleNewSiteRefresh = this.handleNewSiteRefresh.bind(this);
    this.handleLinkProject = this.handleLinkProject.bind(this);
    this.handleHideProject = this.handleHideProject.bind(this);
    this.handleUnhideProject = this.handleUnhideProject.bind(this);
    this.skipXeroToggle = this.skipXeroToggle.bind(this);

    this.resultMessageRef = React.createRef();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(xeroProjectCreateInitializeActionCreator());
    dispatch(fetchEnterpriseProjectDataActionCreator());
    dispatch(optionGet('hidden_projects'));
  }

  componentDidUpdate(prevProps, prevState) {
    const { vendors, trackingCategories, locateToken, loading, error, site: newSite, vendor: newVendor } = this.props.locateInv;
    const { name } = this.props.environment;
    const { dispatch } = this.props;

    if (vendors && !vendors.length && locateToken && !loading
      && !error && prevProps.locateInv.locateToken !== locateToken) {
      dispatch(fetchEnterpriseProjectDataActionCreator());
    }
    if (prevProps.locateInv.trackingCategories !== trackingCategories
      && prevState.tcFiltered !== trackingCategories) {
      this.setState({
        tcFiltered: trackingCategories
      });
    }

    if(newSite !== prevProps.locateInv.site || newVendor !== prevProps.locateInv.vendor) {
      this.handleNewSiteRefresh();
    }

    if(name !== prevProps.environment.name) {
      dispatch(fetchEnterpriseProjectDataActionCreator());
    }
  }

  handleChange(e) {
    if(e.target.value.length < MAX_NAME_LENGTH) {
      const {sitesNames, trackingCategories} = this.props.locateInv;
      this.setState({projectName: e.target.value});
      if (e.target.value.length) {
        this.setState({sitesFiltered: sitesNames.filter(site => site.name.search(new RegExp(e.target.value, 'gi')) >= 0)});
        this.setState({tcFiltered: trackingCategories.filter(tc => tc.name.search(new RegExp(e.target.value, 'gi')) >= 0)});
      } else {
        this.setState({sitesFiltered: sitesNames});
        this.setState({tcFiltered: trackingCategories});
      }
    }
  }

  handleCreateProject() {
    const { dispatch, googleAuth, environment } = this.props;
    dispatch(xeroProjectCreateInitActionCreator(
      {
        projectName: this.state.projectName,
        projectClient: this.state.clientName,
        projectAddr: this.state.projectAddr,
        projectState: this.state.projectState.value,
        projectDate: this.state.projectDate,
        projectType: this.state.projectType,
      },
      googleAuth.token,
      environment,
      this.state.skipXeroCreate)
    );
  }

  handleVendorNameChange(event) {
    this.setState({
      vendorName: event.target.value,
      vendorNameError: event.target.value && this.props.locateInv.vendors.find(v => (v.name === event.target.value || v.name === `Project: ${event.target.value}`))
    });
  }

  handleDialogOpen(tc) {
    this.setState({
      dialogOpen: true,
      selectedXeroProject: tc,
    });
  }

  handleDialogClose() {
    this.setState({
      dialogOpen: false,
      selectedXeroProject: null
    });
  }

  handleSiteSelect(event, value) {
    this.setState({
      selectedSite: value,
    });
  }

  handleCreateVendorWithSite() {
    const { dispatch } = this.props;
    dispatch(createVendorAndVendorSiteLinkWithXero(
      this.state.selectedXeroProject,
      this.state.vendorName
    ));
  }

  handleNewSiteRefresh() {
    const { dispatch } = this.props;
    this.handleDialogClose();
    dispatch(fetchEnterpriseProjectDataActionCreator());
    this.setState({
      vendorName: '',
      selectedSite: null,
    });
  }

  handleLinkProject() {
    const { dispatch } = this.props;
    const { vendors, vendorsIndex } = this.props.locateInv;

    const newVendor = vendors.find(v => v.name === this.state.selectedSite.name || v.id === this.state.selectedSite.vendor_id);

    dispatch(linkVendorSiteToTrackingCategory(
      this.state.selectedXeroProject,
      this.state.selectedSite,
      newVendor,
      vendorsIndex[this.state.selectedXeroProject.id]
    ));
  }

  handleHideProject(project) {
    const { dispatch } = this.props;
    const { option } = this.props.proxy;
    let optionData = [];

    if (option && option.hidden_projects && !Array.isArray(option.hidden_projects)) {
      optionData = [option.hidden_projects];
    } else if (option && option.hidden_projects && Array.isArray(option.hidden_projects)) {
      optionData = option.hidden_projects;
    } else {
      optionData = [];
    }

    optionData.push(project.id);
    const optionsSet = new Set(optionData);
    optionData = Array.from(optionsSet);

    dispatch(optionSet('hidden_projects', optionData));
  }

  handleUnhideProject(project) {
    const { dispatch } = this.props;
    const { option } = this.props.proxy;
    let optionData = [];

    if (option && option.hidden_projects && !Array.isArray(option.hidden_projects)) {
      optionData = [option.hidden_projects];
    } else if (option && option.hidden_projects && Array.isArray(option.hidden_projects)) {
      optionData = option.hidden_projects;
    } else {
      optionData = [];
    }

    optionData = optionData.filter(o => o !== project.id);
    const optionsSet = new Set(optionData);
    optionData = Array.from(optionsSet);

    dispatch(optionSet('hidden_projects', optionData));
  }

  renderResult(link) {
    return link ? (<div ref={this.resultMessageRef}>
      <Alert severity="warning"><b>Important: Please go to <a href={link} rel="noopener noreferrer" target={'_blank'}>this link</a> and complete the site address section on the right of the page.</b></Alert>
        {this.resultMessageRef.current && this.resultMessageRef.current.scrollIntoView({ behavior: 'smooth' })}
      </div>
    ) : null;
  }
  skipXeroToggle(e) {
    this.setState({ skipXeroCreate: e.target.checked });
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, '-', /[0-1]/, /[0-9]/]}
      />
    );
  }

  serverMessage(message) {
    return {
      __html: message
    };
  }

  render() {
    const { messages, progress, link, vendor, site, loading, error } = this.props.xeroLocate;
    const { apiFieldName, sitesIndex, vendorsIndex, vendors,
      sites, loading: lLoading, site: newSite, vendor: newVendor, trackingCategories } = this.props.locateInv;
    const { url } = this.props.environment;
    const { option } = this.props.proxy;
    const hiddenProjects = option && option.hidden_projects ? option.hidden_projects : [];
    const title = 'Enterprise Project Tool';

    return (
      <div>
        <Helmet>
          <title>{ title }</title>
        </Helmet>

        <Backdrop open={lLoading} style={{ zIndex: 1000, flexDirection: 'column' }}>
          <CircularProgress color="inherit" />
          <h2 style={{ color: '#fff' }}>fetching latest data...</h2>
        </Backdrop>

        <LinkDialog
          sites={sites}
          newSite={newSite}
          newVendor={newVendor}
          lLoading={lLoading}
          dialogOpen={this.state.dialogOpen}
          selectedXeroProject={this.state.selectedXeroProject}
          handleCreateVendorWithSite={this.handleCreateVendorWithSite}
          handleNewSiteRefresh={this.handleNewSiteRefresh}
          handleLinkProject={this.handleLinkProject}
          selectedSite={this.state.selectedSite}
          vendorNameError={this.state.vendorNameError}
          handleDialogClose={this.handleDialogClose}
          handleSiteSelect={this.handleSiteSelect}
          handleVendorNameChange={this.handleVendorNameChange}
          vendorName={this.state.vendorName}
          vendors={this.props.locateInv.vendors}
          apiFieldName={apiFieldName}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <h2 style={{ marginBottom: 0 }}>Create a New Enterprise Project in Locate & Xero</h2>
            <p>Before creating a new project, <b>make sure it doesn't exist in either Xero or
              Locate already by searching below.</b></p>
            <p>If we’ve already invoiced the customer, it’s likely that a project has already been
              created in Xero, so you may not need to use this tool. Just find the project in the
              “Existing Xero Projects” list below and link it to an existing or new Locate Vendor
              Site.</p>
          </Grid>
          <Grid item xs={12} sm={12}>
            {this.renderResult(link)}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Project Name"
              placeholder="Input text"
              helperText="Enter the project name. Don’t add “Project:“ in front of it. We’ll handle that for you."
              margin="normal"
              value={this.state.projectName}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Customer Name"
              placeholder="Input text"
              helperText="Enter customer name. Eg.: FP&L / Visa / St Joseph’s Hospital etc."
              margin="normal"
              value={this.state.clientName}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
              onChange={(e) => { if(e.target.value.length < MAX_NAME_LENGTH) { this.setState({ clientName: e.target.value }); } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address"
              fullWidth
              placeholder="Input text"
              helperText="Enter Address Line. Eg.: 12122 Hitching Rail etc."
              margin="normal"
              value={this.state.projectAddr}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              onChange={(e) => { if(e.target.value.length < MAX_NAME_LENGTH) { this.setState({ projectAddr: e.target.value }); } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={statesArr}
              autoSelect
              value={this.state.projectState}
              onChange={(event, value) => {  this.setState({ projectState: value }); }}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select state"
                  variant="outlined"
                  margin="normal"
                  placeholder="start typing..."
                  fullWidth
                  helperText="Select project state from the list"
                />)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.projectType}
                onChange={(e) => { this.setState({ projectType: e.target.value }); }}
                label="Type"
              >
                <MenuItem value={'C'}>Cellular</MenuItem>
                <MenuItem value={'P'}>Public Safety</MenuItem>
                <MenuItem value={'CP'}>Cellular and Public Safety</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" style={{ border: '1px solid #AAA', borderRadius: '4px', paddingBottom: '5px', paddingLeft: '15px'}}>
              <InputLabel htmlFor="projectdate" style={{backgroundColor: '#fff', padding: '2px 5px'}}>Year and Month Invoiced</InputLabel>
              <Input
                id={'projectdate'}
                fullWidth
                value={this.state.projectDate}
                onChange={(e) => { this.setState({ projectDate: e.target.value }); }}
                inputComponent={this.TextMaskCustom}
                type={'text'}
                autoComplete={'cc-exp'}
                disableUnderline
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div
              style={{backgroundColor: '#eee', padding: '15px', borderRadius: '4px', border: '1px solid #aaa'}}
            >
              <strong>Preview:</strong><br />
              <div><strong>Xero Project Name:</strong> {this.state.projectName || '--empty--'} | {this.state.clientName  || '--empty--'} | {this.state.projectAddr} {this.state.projectState ? this.state.projectState.value : '--empty state--'} | {this.state.projectDate || '--empty--'} | {this.state.projectType || '--empty--'}</div>
              <div><strong>Locate Vendor:</strong> Proj: {this.state.projectName || '--empty--'} | {this.state.clientName || '--empty--'} | {this.state.projectAddr} {this.state.projectState ? this.state.projectState.value : '--empty state--'} | {this.state.projectDate || '--empty--'} | {this.state.projectType || '--empty--'}</div>
              <div><strong>Locate Vendor Site:</strong> Proj: {this.state.projectName || '--empty--'} | {this.state.projectDate || '--empty--'} | {this.state.projectType || '--empty--'}</div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>
              <Switch
                checked={this.state.skipXeroCreate}
                onChange={this.skipXeroToggle}
                name="skipXeroCreate"
              /><strong>Select this to SKIP creating a Xero project.</strong> Only use this if there is an existing Xero
              project without a Locate Vendor & Site. You can then use the “Edit Locate Vendor Site” to connect the new
              Locate Vendor & Site to the existing Xero project.
            </div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={this.handleCreateProject}
              style={{ marginTop: '8px', padding: '15px 25px' }}
              disabled={
                (loading || this.state.loading)
                || (!!this.state.tcFiltered.length && !this.state.skipXeroCreate)
                || (!this.state.projectName || !this.state.clientName || !this.state.projectAddr || !this.state.projectState || !this.state.projectDate || !this.state.projectType)
              }
            >Create</Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div>
              <p>This tool does the following:</p>
              <ol>
                <li>Creates a new Project in Xero,</li>
                <li>Creates a new Vendor in Locate & links the Vendor to the new Xero Project,</li>
                <li>Creates new Vendor Site for that Vendor in Locate.</li>
              </ol>
            </div>
            {loading && <LinearProgress style={{ marginTop: '10px' }} variant="determinate" value={progress} />}
            {messages.map((message, index) => <div key={index} dangerouslySetInnerHTML={this.serverMessage(message)} />)}
            {error && <div>{error}</div>}
            {this.renderResult(link)}
          </Grid>
          <Grid item xs={12} sm={12}>
            <h2>Unlinked Locate Vendor Sites</h2>
            <p>This is a list of unlinked vendor sites. All vendor sites for projects should be
              linked to a Xero project.</p>

            {apiFieldName && <UnlinkedProjects
              sites={sites}
              vendors={vendors}
              apiFieldName={apiFieldName}
              trackingCategories={trackingCategories}
              url={url}
            />}

            <Grid container spacing={3}>
              <Grid item xs={8} sm={8}>
                <h2>Existing Xero Projects</h2>
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  id="search"
                  label="Search"
                  style={{ width: '100%' }}
                  placeholder="Input text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><SearchIcon color={'disabled'} /></InputAdornment>,
                  }}
                  value={this.state.projectName}
                  variant="outlined"
                  onChange={this.handleChange}
                />
              </Grid>
            </Grid>

            <ProjectsDataTable
              loading={loading}
              tcFiltered={this.state.tcFiltered}
              sitesIndex={sitesIndex}
              vendorsIndex={vendorsIndex}
              handleDialogOpen={this.handleDialogOpen}
              handleHideProject={this.handleHideProject}
              hidden_projects={hiddenProjects}
              showHidden={false}
              url={url}
            />
            <Grid container spacing={3}>
              <Grid item xs={8} sm={8}>
                <h2>Hidden Xero Projects <Button size={'small'} variant={'outlined'} color={'secondary'} onClick={() => { this.setState({ showHiddenTable: !this.state.showHiddenTable }); }}>{ this.state.showHiddenTable ? 'Hide' : 'Show hidden projects' }</Button></h2>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Collapse in={this.state.showHiddenTable} timeout="auto" unmountOnExit>
                  <ProjectsDataTable
                    loading={loading}
                    tcFiltered={this.state.tcFiltered}
                    sitesIndex={sitesIndex}
                    vendorsIndex={vendorsIndex}
                    handleDialogOpen={this.handleDialogOpen}
                    handleHideProject={this.handleHideProject}
                    handleUnhideProject={this.handleUnhideProject}
                    hidden_projects={hiddenProjects}
                    showHidden
                    url={url}
                  />
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  googleAuth: state.googleAuth,
  environment: state.environment,
  xeroLocate: state.xeroLocate,
  locateInv: state.locateInv,
  proxy: state.proxy
});

export default connect(mapStateToProps)(App);
