import * as ActionTypes from '../../constants/ActionTypes';

const initialState = {
  loading: false,
  token: null,
  error: null,
  option: {},
};

const actionsMap = {
  [ActionTypes.OPTIONS_GET_BY_NAME_START](state) {
    return {
      ...state,
      loading: true,
      error: null
    };
  },
  [ActionTypes.OPTIONS_GET_BY_NAME_SUCCESS](state, payload) {
    const { name, value } = payload.data;
    const newState = {
      ...state,
      error: null,
      loading: false,
    };
    if (!newState.option) {
      Object.assign(newState, { option: {} });
    }
    if (!newState.option[name]) {
      Object.assign(newState.option, { [name]: value });
    } else {
      newState.option[name] = value;
    }

    return newState;
  },
  [ActionTypes.OPTIONS_GET_BY_NAME_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },

  [ActionTypes.OPTIONS_OPTION_SET_START](state) {
    return {
      ...state,
      loading: true,
      error: null
    };
  },
  [ActionTypes.OPTIONS_OPTION_SET_SUCCESS](state, payload) {
    const { name, value } = payload.data;
    const newState = {
      ...state,
      error: null,
      loading: false,
    };
    if (!newState.option) {
      Object.assign(newState, { option: {} });
    }
    if (!newState.option || !newState.option[name]) {
      Object.assign(newState.option, { [name]: value });
    } else {
      newState.option[name] = value;
    }

    return newState;
  },
  [ActionTypes.OPTIONS_OPTION_SET_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },
};

export default function locateInv(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
}
