import React, {useEffect, useState} from 'react';
import {TableRow, TableCell} from '@material-ui/core';

const RowCalculated = ({row, index, rowHandler, url, locationVisibility, uoDisabled}) => {
  const totalQty = parseInt(row.qty_on_hand)
  const [uoQty,setUoQty] = useState(0)
  const [toQtyL1,setToQtyL1] = useState(0)
  const [toQtyL2,setToQtyL2] = useState(0)
  const [toQtyL3,setToQtyL3] = useState(0)
  const [remainingQty,setRemainingQty] = useState(totalQty)

  useEffect(()=>{
    setUoQty(row.UoToQty?.uoQty || 0);
    setToQtyL1(row.UoToQty?.toQtyL1 || 0);
    setToQtyL2(row.UoToQty?.toQtyL2 || 0);
    setToQtyL3(row.UoToQty?.toQtyL3 || 0);
  },[row])

  const handlerSetUoQty = (e) => {
    const newQty = e.target.value === '' ? 0 : parseInt(e.target.value)
    const rQty = totalQty-newQty-toQtyL1-toQtyL2-toQtyL3
    if(newQty<0) setUoQty(0)
    else if(rQty>=0) {
      setUoQty(newQty)
      setRemainingQty(rQty)
    }
  }

  const handlerSetToQtyL1 = (e) => {
    const newQty = e.target.value === '' ? 0 : parseInt(e.target.value)
    const rQty = totalQty-uoQty-newQty-toQtyL2-toQtyL3
    if(newQty<0) setToQtyL1(0)
    else if(rQty>=0) {
      setToQtyL1(newQty)
      setRemainingQty(rQty)
    }
  }

  const handlerSetToQtyL2 = (e) => {
    const newQty = e.target.value === '' ? 0 : parseInt(e.target.value)
    const rQty = totalQty-uoQty-toQtyL1-newQty-toQtyL3
    if(newQty<0) setToQtyL2(0)
    else if(rQty>=0) {
      setToQtyL2(newQty)
      setRemainingQty(rQty)
    }
  }

  const handlerSetToQtyL3 = (e) => {
    const newQty = e.target.value === '' ? 0 : parseInt(e.target.value)
    const rQty = totalQty-uoQty-toQtyL1-toQtyL2-newQty
    if(newQty<0) setToQtyL3(0)
    else if(rQty>=0) {
      setToQtyL3(newQty)
      setRemainingQty(rQty)
    }
  }

  useEffect(()=>{
    if(!locationVisibility.l1) setToQtyL1(0)
    if(!locationVisibility.l2) setToQtyL2(0)
    if(!locationVisibility.l3) setToQtyL3(0)
  },[locationVisibility])

  useEffect(()=>{
    setRemainingQty(totalQty-toQtyL1-toQtyL2-toQtyL3-uoQty)
    rowHandler({uoQty,toQtyL1,toQtyL2,toQtyL3},index)
  },[uoQty,toQtyL1,toQtyL2,toQtyL3])

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <a href={`${url}/app#/part/${row.part_id}/`} rel="noopener noreferrer" target={'_blank'}>{row.part.number}</a>
      </TableCell>
      <TableCell>
        <a href={`${url}/app#/part/${row.part_id}/`} rel="noopener noreferrer" target={'_blank'}>{row.part.name}</a>
      </TableCell>
      <TableCell align="center">{totalQty}</TableCell>
      <TableCell align="center"><input disabled={uoDisabled} className="InputQty" value={uoQty} onChange={handlerSetUoQty} pattern="[0-9]*" type="number"/></TableCell>
      {locationVisibility.l1 && <TableCell align="center"><input className="InputQty" value={toQtyL1} onChange={handlerSetToQtyL1} pattern="[0-9]*" type="number"/></TableCell>}
      {locationVisibility.l2 && <TableCell align="center"><input className="InputQty" value={toQtyL2} onChange={handlerSetToQtyL2} pattern="[0-9]*" type="number"/></TableCell>}
      {locationVisibility.l3 && <TableCell align="center"><input className="InputQty" value={toQtyL3} onChange={handlerSetToQtyL3} pattern="[0-9]*" type="number"/></TableCell>}
      <TableCell align="center">{remainingQty}</TableCell>
    </TableRow>
  )
}

export default RowCalculated
