import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet'
import {useDispatch, useSelector} from "react-redux";
import { ColumnModel,ColumnDataType, ColumnSortDirection } from 'tubular-common';
import {fetchEnterpriseProjectDataActionCreator} from "../../redux/actions/locateInv";
import LinearProgress from "@material-ui/core/LinearProgress";
import {DataGrid, ToolbarOptions} from "tubular-react";
import {TableCell, TableRow} from "@material-ui/core";

const styles = {
  progress: {
    marginTop: "20px",
    height: "20px"
  },
  search: {
    margin: "15px 10px 10px 10px",
    textAlign: "right"
  }
};


const columns = [
  new ColumnModel('id', {
    Sortable: true,
    Label: 'ID'
  }),
  new ColumnModel('name', {
    Sortable: true,
    Searchable: true,
    Filterable: true,
    Label: 'Name'
  }),
  new ColumnModel('sitetypetext', {
    Sortable: true,
    Label: 'Type',
    Filterable: true
  }),
  new ColumnModel('value_on_hand_text', {
    DataType:  ColumnDataType.NUMERIC,
    Sortable: true,
    SortOrder:  1,
    Label: 'Value On Hand',
    SortDirection:  ColumnSortDirection.DESCENDING
  }),
];

export const HomeInboundStock = () => {
  const {locateInv,environment} = useSelector(data=>data);
  const {loading} = locateInv;
  const sites = locateInv.sites.map(item=>({
    ...item,
    sitetypetext:item.sitetype.name,
    value_on_hand_text: item.value_on_hand ? parseFloat(item.value_on_hand.inventory_value_on_hand) : 0
  }));

  const dispatch = useDispatch();
  const title = 'Inbound Stock Tool';
  useEffect(()=>{
    if(environment.name) {
      dispatch(fetchEnterpriseProjectDataActionCreator());
    }
  },[environment.name]);

  return  (
    <div>
      <Helmet>
        <title>{ title }</title>
      </Helmet>
      <div style={styles.progress}>
        {loading &&<LinearProgress/> }
      </div>
      <DataGrid
        columns={columns}
        // sortColumn={(a, b) => { console.log(a, b); }}
        dataSource={sites}
        gridName="Sites"
        toolbarOptions={new ToolbarOptions({
          itemsPerPage: 30,
          bottomPager: true,
          topPager: true,
          printButton: false,
          exportButton: false,
          searchText: true,
          rowsPerPageOptions: [10, 30, 50, 100]
        })}
        rowComponent={({ row: order }) => {
          return (
            <TableRow hover key={order.id} style={{ backgroundColor: order.color }}>
              <TableCell size="small">{order.id}</TableCell>
              <TableCell size="small"><a  href={`/app/inbound-stock-tool#vendorSite(${order.id})`}>{order.name}</a></TableCell>
              <TableCell size="small">{order.sitetypetext}</TableCell>
              <TableCell size="small">{new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(order.value_on_hand_text)}</TableCell>
            </TableRow>
          );
        }}
      />
    </div>
  );
}
