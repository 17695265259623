import { PROXY_API_BASE_URL } from '../../constants/settings';
import {
  OPTIONS_GET_BY_NAME_START,
  OPTIONS_GET_BY_NAME_SUCCESS,
  OPTIONS_GET_BY_NAME_ERROR,
  OPTIONS_OPTION_SET_START,
  OPTIONS_OPTION_SET_SUCCESS,
  OPTIONS_OPTION_SET_ERROR
} from '../../constants/ActionTypes';
import {getAPIToken} from "./locateInv";

export function optionGet(name) {
  return async (dispatch, getState) => {
    dispatch({
      type: OPTIONS_GET_BY_NAME_START
    });
    const state = getState();
    const token = await getAPIToken();
    return fetch(`${PROXY_API_BASE_URL}/options/${name}`, {
      headers: new Headers({
        'authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      })
    }).then(response => {
      if (response.status === 401) {
        return Promise.reject(401);
      }
      return response.json();
    })
      .then(json => {
        const data = {
          name,
          value: JSON.parse(json.option_value)
        };
        dispatch({
          type: OPTIONS_GET_BY_NAME_SUCCESS,
          data
        });
      })
      .catch(error => dispatch({ type: OPTIONS_GET_BY_NAME_ERROR, error }));
  };
}

export function optionSet(name, value) {

  return async (dispatch, getState) => {
    dispatch({
      type: OPTIONS_OPTION_SET_START
    });
    const state = getState();
    const token = await getAPIToken();

    return fetch(`${PROXY_API_BASE_URL}/options`, {
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer '+ token,
      },
      body: JSON.stringify({ name, value }),
      method: 'post',
      redirect: 'follow'
    }).then(response => {
      if (response.status === 401) {
        return Promise.reject(401);
      }
      return response.json();
    })
      .then(json => {
        const data = {
          name,
          value: JSON.parse(json.option_value)
        };
        dispatch({
          type: OPTIONS_OPTION_SET_SUCCESS,
          data
        });
      })
      .catch(error => dispatch({ type: OPTIONS_OPTION_SET_ERROR, error }));
  };
}
