import * as ActionTypes from '../../constants/ActionTypes';

const initialState = {
  token: null,
  error: null,
  user: {
    email: null,
    id: null,
    name: null,
    image: null
  }
};

const actionsMap = {
  [ActionTypes.GOOGLE_AUTHENTICATION_START](state) {
    return { ...state, error: null };
  },
  [ActionTypes.GOOGLE_AUTHENTICATION_SUCCESS](state, payload) {
    return { ...state,
      token: payload.token,
      error: null,
    };
  },
  [ActionTypes.GOOGLE_PROFILE_LOADED](state, payload) {
    return { ...state,
      error: null,
      user: {
        email: payload.email,
        id: payload.id,
        name: payload.name,
        image: payload.image,
      }
    };
  },
  [ActionTypes.GOOGLE_AUTHENTICATION_TOKEN_EXPIRE](state) {
    return { ...state,
      token: null,
      user: {
        email: null,
        id: null
      }
    };
  },
  [ActionTypes.GOOGLE_AUTHENTICATION_DOMAIN_ACCESS_DENIED](state) {
    return { ...state,
      token: null,
      error: 'ACCESS_DENIED',
      user: {
        email: null,
        id: null
      }
    };
  },
};


export default function googleAuth(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
}
