import React  from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Slide from '@material-ui/core/Slide';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {locateInvReceiveVendorReceipt} from '../../redux/actions/locateInv';

import styles from './ToPoDataTable.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ConfirmReceiveDialog({order, url, dispatch, products}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReceive = () => {
    setLoading(true);
    dispatch(locateInvReceiveVendorReceipt(order.id, order.type === 'PurchaseOrder' ? 'purchaseorder' : 'transferorder'))
  };

  return (
    <div>
      <Button variant="contained" size="small" color="primary" onClick={handleClickOpen} className={styles.receiveButton}>
        receive
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <b>This will receive all the parts on this PO/TO.</b>
          </DialogContentText>
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell><b>Part Name</b></TableCell>
                  <TableCell align="right"><b>QTY.</b></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.partNumber}>
                    <TableCell component="th" scope="row">{product.partName}</TableCell>
                    <TableCell align="right">{product.qty}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <DialogContentText id="alert-dialog-slide-description">
            <b>If some items are missing you need to 'Receive Short' - <a rel="noopener noreferrer" href={`${url}/app#/${order.type === 'PurchaseOrder' ? 'purchaseorder' : 'transferorder'}/${order.id}/profile`} target={'_blank'}>click here</a> to go to Locate and receive short</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleReceive} color="primary" disabled={loading}>
            Receive
          </Button>
        </DialogActions>
        <LinearProgress color="secondary" hidden={!loading} />
      </Dialog>
    </div>
  );
}

export default connect()(ConfirmReceiveDialog);
