import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import styles from './App.css';

export default function UnlinkedProjects({ sites, vendors, url, apiFieldName, trackingCategories }) {
  let unlinked = [];
  if (sites && vendors && sites.length && vendors.length) {
    unlinked = sites.filter(site => (vendors.find(vendor => {
      return vendor.id === site.vendor_id && (vendor[apiFieldName] === null || (vendor[apiFieldName] && vendor[apiFieldName].id && !trackingCategories.find(tc => tc.id === vendor[apiFieldName].id)) );
    } )));
  }

  return (<div className={styles['unlinked-wrapper']}>
    <Grid container spacing={2}>
      {unlinked.map(vendorSite => (<Grid key={vendorSite.id} item xs={4} sm={4}><a target={'_blank'} rel="noopener noreferrer" href={`${url}/app#/site/${vendorSite.id}/details`}>{vendorSite.name}</a></Grid>))}
    </Grid>
  </div>);
}

UnlinkedProjects.propTypes = {
  sites: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
  apiFieldName: PropTypes.string.isRequired,
  trackingCategories: PropTypes.array
};
