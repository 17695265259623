import {
  XERO_PROJECT_CREATE_ERROR,
  XERO_PROJECT_CREATE_SUCCESS,
  XERO_PROJECT_CREATE_MESSAGE,
  XERO_PROJECT_CREATE_INITIALIZE,
  XERO_PROJECT_CREATE_START_LOADING,
  XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES,
  XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_SUCCESS,
  XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_STRAT,
  XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_ERROR
} from '../../constants/ActionTypes';

import { EventSourcePolyfill } from 'event-source-polyfill';
import { PROXY_API_BASE_URL } from '../../constants/settings';
import {getAPIToken} from "./locateInv";


export function xeroProjectCreateInitActionCreator({
                                                     projectName,
                                                     projectClient,
                                                     projectAddr,
                                                     projectState,
                                                     projectDate,
                                                     projectType,
                                                   },
                                                   token,
                                                   env,
                                                   skipXeroCreate) {
  return dispatch => new Promise(async (resolve) => {
    dispatch(xeroProjectCreateStartActionCreator());
    const token = await getAPIToken();
    const headers ={
      'authorization': 'Bearer '+ token,
      'Content-Type': 'application/json'
    };
    const stream = new EventSourcePolyfill(
      `${PROXY_API_BASE_URL}/xero-locate/create-project?name=${projectName}&client=${projectClient}&addr=${projectAddr}&state=${projectState}&date=${projectDate}&type=${projectType}&env=${env.name}&skip_xero_create=${skipXeroCreate ? 1 : 0}`,
      {
        headers
      }
    );
    stream.addEventListener('message', (event) => {
      const streamData = JSON.parse(event.data);

      const { message, progress, status, data } = streamData;

      if (progress && ['init', 'message'].includes(status)) {
        dispatch(xeroProjectCreateMessageActionCreator(message, progress));
      }
      if (status === 'success') {
        dispatch(xeroProjectCreateSuccessActionCreator(data, progress, message));
        stream.close();
        resolve(data);
      }
      if (status === 'error') {
        dispatch(xeroProjectCreateErrorActionCreator(message));
        stream.close();
        resolve(message);
      }
    });

    stream.addEventListener('error', () => {
      stream.close();
    });
  });
}

export function xeroProjectCreateErrorActionCreator(error) {
  return {
    type: XERO_PROJECT_CREATE_ERROR,
    error
  };
}

export function xeroProjectCreateSuccessActionCreator(data, progress, message) {
  return {
    type: XERO_PROJECT_CREATE_SUCCESS,
    link: data.link,
    vendor: data.vendor,
    site: data.site,
    progress,
    message
  };
}

export function xeroProjectCreateMessageActionCreator(message, progress) {
  return {
    type: XERO_PROJECT_CREATE_MESSAGE,
    message,
    progress
  };
}
export function xeroProjectCreateStartActionCreator() {
  return {
    type: XERO_PROJECT_CREATE_START_LOADING
  };
}
export function xeroProjectCreateInitializeActionCreator() {
  return {
    type: XERO_PROJECT_CREATE_INITIALIZE
  };
}

export function xeroProjectFetchAllTrackingCategoriesActionCreator() {
  return {
    type: XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES
  };
}

export function xeroFetchAllTrackingCategoriesStart() {
  return {
    type: XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_STRAT
  };
}

export function xeroFetchAllTrackingCategoriesSuccess(data) {
  return {
    type: XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_SUCCESS,
    data
  };
}
export function xeroFetchAllTrackingCategoriesError(error) {
  return {
    type: XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_ERROR,
    error
  };
}
