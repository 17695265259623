import randomcolor from "randomcolor";

// eslint-disable-next-line class-methods-use-this
const transformOrders = (orders) => {
  const newOrders = [];
  orders.forEach((o) => {
    const color = randomcolor({ luminosity: 'light', format: 'rgba', alpha: 0.14 });
    if (o.products && o.products.length) {
      o.products.forEach((p) => {
        let pickupLine;
        let trackingNumber;
        if (o.type === 'PurchaseOrder') {
          trackingNumber = o.receipts.map(r => r.tracking_number).filter(tn => tn).join(', ');
        } else {
          pickupLine = o.pickuplines.find(pl =>
            pl.carton.contents.find(item =>
              item.part_id === p.partId));
          if (pickupLine) {
            trackingNumber = pickupLine.carton.tracking_number;
          }
        }
        const order = {
          ...o,
          color,
          statusName: o.status.name,
          productQty: p.qty,
          productPartName: p.partName,
          productPartNumber: p.partNumber,
          productPartId: p.partId,
          productLineStatus: p.status,
          productLineStatusName: p.status.name,
          trackingNumber
        };
        newOrders.push(order);
      });
    } else {
      const order = {
        ...o,
        color,
      };
      newOrders.push(order);
    }
  });

  return newOrders;
}

export default transformOrders
