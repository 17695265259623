import React from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableSortLabel from '@material-ui/core/TableSortLabel';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ProjectsDataTable({
  loading,
  tcFiltered,
  sitesIndex,
  vendorsIndex,
  handleDialogOpen,
  handleHideProject,
  handleUnhideProject,
  hidden_projects,
  showHidden,
  url
}) {

  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (<TableContainer component={Paper}>
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell component="th">
            <TableSortLabel
              active={orderBy === 'name'}
              direction={orderBy === 'name' ? order : 'asc'}
              onClick={createSortHandler('name')}
            >Xero Project Name
            </TableSortLabel>
          </TableCell>
          <TableCell component="th"><b>Locate Vendor Site</b></TableCell>
          <TableCell component="th"><b>Locate Vendor Name</b></TableCell>
          <TableCell component="th"><b>Update</b></TableCell>
        </TableRow>
      </TableHead>
      {!loading && <TableBody>
        {stableSort(tcFiltered.filter(row => (showHidden ? hidden_projects.includes(row.id) : !hidden_projects.includes(row.id))), getComparator(order, orderBy)).map(row => (
          <TableRow key={row.name}>
            <TableCell scope="row">
              {row.name}
            </TableCell>
            <TableCell>
              {sitesIndex && sitesIndex[row.id] ? (<a target={'_blank'} rel="noopener noreferrer" href={`${url}/app#/site/${sitesIndex[row.id].id}/details`}>{sitesIndex[row.id].name}</a>) : ''}
              {!sitesIndex[row.id] && (<b>Not Linked</b>)}
            </TableCell>
            <TableCell>
              {vendorsIndex && vendorsIndex[row.id] ? (<a target={'_blank'} rel="noopener noreferrer" href={`${url}/app#/vendor/${vendorsIndex[row.id].id}/`}>{vendorsIndex[row.id].name}</a>) : ''}
              {vendorsIndex && !vendorsIndex[row.id] && (<b>&mdash;</b>)}
            </TableCell>
            <TableCell>
              <ButtonGroup color="primary">
                {!showHidden && <Button
                  color="primary"
                  onClick={() => { handleDialogOpen(row); }}
                >Edit Locate Vendor Site</Button>}
                {showHidden
                  ? <Button onClick={() => { handleUnhideProject(row); }}>Unhide</Button>
                  : <Button onClick={() => { handleHideProject(row); }}>Hide</Button>}
              </ButtonGroup>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>}
    </Table>
  </TableContainer>);
}

ProjectsDataTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  showHidden: PropTypes.bool.isRequired,
  tcFiltered: PropTypes.array.isRequired,
  sitesIndex: PropTypes.object.isRequired,
  vendorsIndex: PropTypes.object.isRequired,
  handleDialogOpen: PropTypes.func.isRequired,
  handleHideProject: PropTypes.func,
  handleUnhideProject: PropTypes.func,
  hidden_projects: PropTypes.array.isRequired,
  url: PropTypes.string.isRequired,
};
