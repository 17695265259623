import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {locateInvFetchDropShipPOs, locateConfirmDropShipPOs} from "../../redux/actions/locateInv";
import LinearProgress from "@material-ui/core/LinearProgress";
import {DataGrid, ToolbarOptions} from 'tubular-react';
import {ColumnModel} from 'tubular-common';
import {TableCell, TableRow} from "@material-ui/core";

import {Tooltip} from '@material-ui/core';

import Button from "@material-ui/core/Button";
import MuiAlert from '@material-ui/lab/Alert';
import transformPurchaseOrders from "../../utils/transformPurchaseOrder";
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = {
  progress: {
    marginTop: "20px",
    height: "20px"
  },
  search: {
    margin: "15px 10px 10px 10px",
    textAlign: "right"
  }
};


const columns = [
  new ColumnModel('id', {
    Visible: false,
    Searchable: false
  }),
  new ColumnModel('po_number', {
    Sortable: true,
    Label: 'Number',
    Searchable: true,
    Visible: true
  }),
  new ColumnModel('vendor', {
    Sortable: true,
    Label: 'Vendor',
    Searchable: false,
    Visible: true
  }),
  new ColumnModel('type', {
    Sortable: false,
    Label: 'Type',
    Searchable: false,
    Visible: true
  }),
  new ColumnModel('status', {
    Sortable: false,
    Label: 'Status',
    Searchable: false,
    Visible: true

  }),
  new ColumnModel('created_at', {
    Sortable: true,
    Label: 'Created At',
    Searchable: false,
    Visible: true

  }),

  new ColumnModel('products', {
    Sortable: true,
    Label: 'Items',
    Searchable: false
  }),

  new ColumnModel('total_cost', {
    Sortable: true,
    Label: 'Total Cost',
    Searchable: false
  }),

  new ColumnModel('tracking_number', {
    Sortable: false,
    Label: 'Tracking Number',
    Searchable: false
  }),
  new ColumnModel('notes', {
    Sortable: false,
    Searchable: false,
    Visible: false
  }),
  new ColumnModel('receipts', {
    Sortable: false,
    Searchable: false,
    Visible: false
  }),

  new ColumnModel('o_tracking_number', {
    Sortable: false,
    Searchable: false,
    Visible: false
  }),

];

export const HomeDropShipTracking = () => {
  const {locateInv, environment} = useSelector(data => data);
  const {loading} = locateInv;
  const dropshipPo = locateInv.dropshipPo;
  const dispatch = useDispatch();
  let newDropshipPo = transformPurchaseOrders(dropshipPo);
  let confirmationStatus = locateInv.dropshipPoConfirmed;

  let confirmationError = locateInv.dropshipPoError;
  useEffect(() => {
    if (environment.name) {
      reloadJobs();
    }
  }, [environment.name]);

  const reloadJobs = () => {
    dispatch(locateInvFetchDropShipPOs());
    return '';
  };
  const changeDateFormat = (dateString) => {
    let date = new Date(dateString);

    let formatedDate = date.toLocaleDateString().split('/').join('-');

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let formatedTime = hours + ':' + minutes + ' ' + ampm;

    return formatedDate + " " + formatedTime;
  };

  const getItemsList = (products) => {
    let itemsList = '<p>';
    products.forEach((product) => {
      itemsList += '<span>' + product.qty + ' x ' + product.partName + '</span><br/>'
    });
    itemsList += '</p>';
    return itemsList;
  };

  const submitTrackingNumber = (order_id, event) => {
    event.preventDefault();
    let activeOrder = newDropshipPo.find(elem => elem.id == order_id);
    if (activeOrder) {
      if (activeOrder.status.id == 14) {
        alert('This Receipt is Already Confirmed');
        return false;
      }
      dispatch(locateConfirmDropShipPOs(order_id, activeOrder.receipts, activeOrder.o_tracking_number));

    }
  };

  const handleInput = (order_id, event) => {
    let index = newDropshipPo.findIndex(elem => elem.id == order_id);
    if (index > -1) {
      newDropshipPo[index]['o_tracking_number'] = event.target.value;
    }
    return true;
  };

  const getOrderUrl = (order_id) => {
    if (environment.name == 'Dev') {
      return `https://test-repeaterstore.locateinv.com/app#/purchaseorder/${order_id}/details`;
    } else {
      return `https://repeaterstore.locateinv.com/app#/purchaseorder/${order_id}/details`;
    }

  };
  /*
  * conditions:
  * A tracking number doesn't exist for any of the line items
  * None of the items have been confirmed
  * warnings:
  * "Tracking number already exists. Please log in to locate to fix the issue"
  * "Order is already partly confirmed. Please log in to locate to fix the issue"
  * */
  const checkValidOrder = (order) => {

    let validationResult = {
      valid: true,
      warning: ''
    };
    let linesValid = true;
    order.products.forEach((product) => {
      if (product.status.is_complete_status != 0) {
        linesValid = false;
        return 0;
      }
    });

    let receiptValid = true;
    let trackingNumberAbsent = true;
    if(order.receipts){
      order.receipts.forEach((receipt) => {
        if (receipt.status_id == 14) {
          receiptValid = false;
        }
        if(receipt.tracking_number){
          trackingNumberAbsent = false;
        }

      });

    }

    if (!receiptValid && !linesValid && !trackingNumberAbsent) {
      validationResult = {
        valid: false,
        warning: 'Order is already partly confirmed and Tracking number already exists. Please log in to locate to fix the issue'
      };
    } else if (!trackingNumberAbsent) {
      validationResult = {
        valid: false,
        warning: 'Tracking number already exists. Please log in to locate to fix the issue'
      };
    }
    else if (!receiptValid) {
      validationResult = {
        valid: false,
        warning: 'Receipt Already Confirmed. Please log in to locate to fix the issue'
      };
    } else if (!linesValid) {
      validationResult = {
        valid: false,
        warning: 'Order is already partly confirmed. Please log in to locate to fix the issue'
      };
    }

    return validationResult;
  };
  return (
    <div>
      <div style={styles.progress}>
        {loading && <LinearProgress/>}
      </div>
      <p>
        <strong>Note:</strong>
        <i>we only want to use this system where we are shipping all the parts in the order. Initially orders that are
          partly shipped should be dealt with via Locate.
        </i>
      </p>
      {confirmationStatus == true &&
      <Alert severity="success">Order Confirmed Successfully!</Alert>
      }

      {confirmationError == true &&
      <Alert severity="error">Unable to Confirm the Order, Please log in to locate to fix the issue!</Alert>
      }

      <DataGrid
        columns={columns}
        dataSource={newDropshipPo}
        gridName="Dropship Purchase Orders List"
        toolbarOptions={new ToolbarOptions({
          itemsPerPage: 30,
          bottomPager: true,
          topPager: true,
          printButton: false,
          exportButton: false,
          searchText: true,
          rowsPerPageOptions: [10, 30, 50, 100]
        })}
        rowComponent={({row: order}) => {

          let orderValidation = checkValidOrder(order);
          return (
            <TableRow hover key={order.id} style={{backgroundColor: order.color}}>

              <TableCell size="small">
                <a
                  href={getOrderUrl(order.id)} target="_blank">{order.po_number}</a>
                {order.notes && order.notes.length > 0 && <Tooltip title={order.notes[0].note}>
                  <img src="/images/notes_icon.jpg" style={{'height': '20px'}} alt="Order Notes"/>
                </Tooltip>}
              </TableCell>

              <TableCell size="small">{order.vendor.name}</TableCell>
              <TableCell size="small">Dropship</TableCell>
              <TableCell size="small">{order.status.name}</TableCell>
              <TableCell size="small">{changeDateFormat(order.created_at)}</TableCell>
              <TableCell size="small" dangerouslySetInnerHTML={{__html: getItemsList(order.products)}}></TableCell>
              <TableCell size="small">${order.total_cost}</TableCell>
              <TableCell size="small">
                <form className="search-form" method="POST" onSubmit={(e) => submitTrackingNumber(order.id, e)}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <input type="hidden" name="order_id" value={order.id}/>
                          <input type="text" name="tracking_number" defaultValue={order.o_tracking_number}
                                 style={{'padding': '9px'}}
                                 onChange={(e) => handleInput(order.id, e)} required disabled={!orderValidation.valid}/>
                        </td>
                        <td>
                          <Button type="submit" variant="contained" color="primary" disabled={!orderValidation.valid}
                          >Submit
                          </Button>
                        </td>
                        {!orderValidation.valid &&
                        <td><Tooltip title={orderValidation.warning}>
                          <ReportProblemOutlinedIcon/>
                        </Tooltip></td>
                        }
                      </tr>
                    </tbody>
                  </table>
                </form>

              </TableCell>

            </TableRow>

          );
        }}
      />

    </div>
  );
}
