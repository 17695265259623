import React from 'react';
import Button from "@material-ui/core/Button";

const CreateToStatus = (props) => {
  const {
    url,
    createTo,
    createToList,
    createToIssue,
    createToHandler,
    disabledCreateButtons
  } = props;

  return (
    <div style={{display: 'flex'}}>
      <div style={{width: 150}}>
        {(createTo.loading || createToList.loading || createToIssue.loading) ?
          <Button disabled color="secondary" variant="contained">In Progress</Button>
          :
          !createToIssue.order ?
            <Button disabled={disabledCreateButtons} onClick={createToHandler} variant="contained" color="secondary">Create TO</Button>
            :
            <Button disabled color="secondary" variant="contained">Complete</Button>
        }
      </div>
      {(createTo.loading || createTo.order || createTo.error) &&
      <div>
        <b>Status:</b><br/>
        <ol>
          {(createTo.loading || createTo.order || createTo.error) &&
          <li>
            <span>Creating TO ….</span>
            {createTo.order && <span><b style={{color: 'green'}}>Success!</b> Here is the TO:<a  href={`${url}/app#/transferorder/${createTo.order.id}/`} rel="noopener noreferrer" target={'_blank'}>TO #{createTo.order.number}</a></span>}
            {createTo.error && <span><b style={{color: 'red'}}>Error!</b></span>}
          </li>
          }
          {(createToList.loading || createToList.order || createToList.error) &&
          <li>
            <span>Adding Line Items ….</span>
            {createToList.order && <span><b style={{color: 'green'}}>Success!</b></span>}
            {createToList.error && <span><b style={{color: 'red'}}>Error!</b></span>}
          </li>
          }
          {(createToIssue.loading || createToIssue.order || createToIssue.error) &&
          <li>
            <span>Issuing TO ….</span>
            {createToIssue.order && <span><b style={{color: 'green'}}>Success!</b> <br/>Here is the link to your TO: <a  href={`${url}/app#/transferorder/${createTo.order.id}/`} rel="noopener noreferrer" target={'_blank'}>TO #{createTo.order.number}</a></span>}
            {createToIssue.error && <span><b style={{color: 'red'}}>Error!</b></span>}
          </li>
          }
        </ol>
        {createTo.error && <b>Try again, or ask someone for help if you keep getting this error (send them the error shown above).</b>}
        {createToList.error && <b>Before clicking “Create TO” again, delete the TO above in Locate. If you get this error more than once, ask someone for help (send them the error message shown above).</b>}
        {createToIssue.error && <b>Try opening the TO in Locate and issuing it yourself.<br /> Before clicking “Create TO” again, delete the TO above in Locate. If you get this error more than once, ask someone for help (send them the error message shown above).</b>}
      </div>
      }
    </div>
  )
}

export default CreateToStatus;
