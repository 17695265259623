import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';

import styles from './App.css';

export default function LinkDialog({
  sites,
  newSite,
  newVendor,
  lLoading,
  handleNewSiteRefresh,
  handleLinkProject,
  selectedSite,
  handleCreateVendorWithSite,
  vendorNameError,
  handleDialogClose,
  dialogOpen,
  selectedXeroProject,
  handleSiteSelect,
  handleVendorNameChange,
  vendorName,
  vendors,
  apiFieldName
}) {

  const selectedVendor = selectedSite && vendors.find(v => v.name === selectedSite.name || v.id === selectedSite.vendor_id) || null;
  const selectedVendorLinked = selectedSite && selectedVendor && selectedVendor[apiFieldName] !== null || false;

  return (<Dialog
    open={dialogOpen}
    onClose={handleDialogClose}
    fullWidth
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle
      className={styles.customDialogHeader}
    >Edit Existing Xero Project
    </DialogTitle>
    <DialogContent>
      <div className="dialogContentHeader">
        <p>Xero Project Name:</p>
        <h2>{selectedXeroProject && selectedXeroProject.name}</h2>
      </div>
      <div className={styles.inputWrapper}>
        <h3>Link to an existing Locate Vendor Site:</h3>
        <Autocomplete
          style={{ display: 'inline-block' }}
          id="outlined-select-site"
          className="sitesDropdown"
          options={sites}
          autoSelect
          onChange={handleSiteSelect}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              label="Locate Vendor Site"
              variant="outlined"
              placeholder="Input text"
              fullWidth
            />)
          }
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleLinkProject}
          disabled={!selectedSite || lLoading}
          style={{ marginLeft: '15px', padding: '15px 20px', width: '8em', position: 'absolute' }}
        >Update</Button>
      </div>
      { selectedVendorLinked && <p style={{ display: selectedVendorLinked ? 'block' : 'none', color: 'red' }}><span>Warning!</span> This Site is already linked to <b>{selectedVendor[apiFieldName].name}</b></p> }
      {/*<div className='inputWrapper'>*/}
        {/*<h3>Or create a New Vendor and Vendor Site:</h3>*/}
        {/*<TextField*/}
          {/*error={vendorNameError}*/}
          {/*id="outlined-full-width"*/}
          {/*label="Project Name"*/}
          {/*className="sitesDropdown"*/}
          {/*placeholder="Input text"*/}
          {/*helperText={*/}
            {/*vendorNameError*/}
              {/*? 'Vendor with the same Name already exists'*/}
              {/*: 'Enter the project name. Don’t add “Project:“ in front of it. We’ll handle that for you.'*/}
          {/*}*/}
          {/*margin="normal"*/}
          {/*value={vendorName}*/}
          {/*InputLabelProps={{*/}
            {/*shrink: true,*/}
          {/*}}*/}
          {/*variant="outlined"*/}
          {/*onChange={handleVendorNameChange}*/}
        {/*/>*/}
        {/*<Button*/}
          {/*variant="contained"*/}
          {/*color="primary"*/}
          {/*size="large"*/}
          {/*onClick={handleCreateVendorWithSite}*/}
          {/*disabled={!vendorName || vendorNameError || lLoading}*/}
          {/*style={{ marginTop: '16px', marginLeft: '15px', padding: '15px 25px', width: '8em' }}*/}
        {/*>Create</Button>*/}
      {/*</div>*/}
      {lLoading && <div><LinearProgress color="secondary" /></div>}
      {/*<div>*/}
        {/*<p>This tool does the following:</p>*/}
        {/*<ol>*/}
          {/*<li>Create a new Vendor in Locate,</li>*/}
          {/*<li>Link the new Vendor to the new Xero Project,</li>*/}
          {/*<li>Create a new Vendor Site for that Vendor in Locate.</li>*/}
        {/*</ol>*/}
      {/*</div>*/}

    </DialogContent>
    <DialogActions>
      <Button onClick={handleDialogClose} color="primary" autoFocus>
        close
      </Button>
    </DialogActions>
  </Dialog>);
}

LinkDialog.propTypes = {
  sites: PropTypes.array.isRequired,
  newSite: PropTypes.object,
  newVendor: PropTypes.object,
  lLoading: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  handleNewSiteRefresh: PropTypes.func.isRequired,
  handleLinkProject: PropTypes.func.isRequired,
  selectedSite: PropTypes.object,
  selectedXeroProject: PropTypes.object,
  handleCreateVendorWithSite: PropTypes.func.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  handleSiteSelect: PropTypes.func.isRequired,
  handleVendorNameChange: PropTypes.func.isRequired,
  vendorNameError: PropTypes.bool.isRequired,
  vendorName: PropTypes.string.isRequired,
  vendors: PropTypes.array,
  apiFieldName: PropTypes.string,
};
