import {
  LOCATEINV_FETCH_SITES_INIT,
  LOCATEINV_FETCH_PO_TO_INIT,
  LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_INIT,
  LOCATEINV_FETCH_SITES_START,
  LOCATEINV_FETCH_SITES_SUCCESS,
  LOCATEINV_FETCH_SITES_ERROR,
  LOCATEINV_FETCH_PO_TO_START,
  LOCATEINV_FETCH_PO_TO_SUCCESS,
  LOCATEINV_FETCH_PO_TO_ERROR,
  LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_START,
  LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_SUCCESS,
  LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_ERROR,
  LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_START,
  LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_SUCCESS,
  LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_ERROR,
  LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_START,
  LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_SUCCESS,
  LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_ERROR,
  LOCATEINV_LINK_VENDOR_SITE_WITH_TC_START,
  LOCATEINV_LINK_VENDOR_SITE_WITH_TC_SUCCESS,
  LOCATEINV_LINK_VENDOR_SITE_WITH_TC_ERROR,
  LOCATEINV_FETCH_INVENTORY_START,
  LOCATEINV_FETCH_INVENTORY_SUCCESS,
  LOCATEINV_FETCH_INVENTORY_ERROR,
  LOCATEINV_FETCH_INVENTORY_INIT,
  LOCATEINV_CREATE_UO_START,
  LOCATEINV_CREATE_UO_SUCCESS,
  LOCATEINV_CREATE_UO_ERROR,
  LOCATEINV_CREATE_UO_LIST_START,
  LOCATEINV_CREATE_UO_LIST_SUCCESS,
  LOCATEINV_CREATE_UO_LIST_ERROR,
  LOCATEINV_ISSUE_UO_START,
  LOCATEINV_ISSUE_UO_SUCCESS,
  LOCATEINV_ISSUE_UO_ERROR,
  LOCATEINV_CREATE_TO1_START,
  LOCATEINV_CREATE_TO1_SUCCESS,
  LOCATEINV_CREATE_TO1_ERROR,
  LOCATEINV_CREATE_TO1_LIST_START,
  LOCATEINV_CREATE_TO1_LIST_SUCCESS,
  LOCATEINV_CREATE_TO1_LIST_ERROR,
  LOCATEINV_ISSUE_TO1_START,
  LOCATEINV_ISSUE_TO1_SUCCESS,
  LOCATEINV_ISSUE_TO1_ERROR,
  LOCATEINV_CREATE_TO2_START,
  LOCATEINV_CREATE_TO2_SUCCESS,
  LOCATEINV_CREATE_TO2_ERROR,
  LOCATEINV_CREATE_TO3_START,
  LOCATEINV_CREATE_TO3_SUCCESS,
  LOCATEINV_CREATE_TO3_ERROR,
  LOCATEINV_CREATE_TO2_LIST_START,
  LOCATEINV_CREATE_TO2_LIST_SUCCESS,
  LOCATEINV_CREATE_TO2_LIST_ERROR,
  LOCATEINV_CREATE_TO3_LIST_START,
  LOCATEINV_CREATE_TO3_LIST_SUCCESS,
  LOCATEINV_CREATE_TO3_LIST_ERROR,
  LOCATEINV_ISSUE_TO2_START,
  LOCATEINV_ISSUE_TO2_SUCCESS,
  LOCATEINV_ISSUE_TO2_ERROR,
  LOCATEINV_ISSUE_TO3_START,
  LOCATEINV_ISSUE_TO3_SUCCESS,
  LOCATEINV_ISSUE_TO3_ERROR,
  LOCATEINV_CLEAR_UO_TO,
  LOCATEINV_FETCH_DROPSHIP_PO_START,
  LOCATEINV_FETCH_DROPSHIP_PO_SUCCESS,
  LOCATEINV_FETCH_DROPSHIP_PO_ERROR,
  LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_START,
  LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_SUCCESS,
  LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_ERROR,

} from '../../constants/ActionTypes';

import {DO_API_PASSWORD, DO_API_USERNAME, PROXY_API_BASE_URL} from "../../constants/settings";

/**
 * @param endpoint String
 * @param dispatch Redux dispatch function
 * @param state Object current state
 * @param dispatchTypes = { start: 'LOCATEINV_ORDER_FETCH_START', success: 'LOCATEINV_ORDER_FETCH_SUCCESS', error: 'LOCATEINV_ORDER_FETCH_ERROR' }
 * @param method = [GET, POST, PATCH, DELETE]
 */
export const fetchRequest = async (endpoint, dispatch, state, dispatchTypes, method = 'get', data) => {
  dispatch({ type: dispatchTypes.start });
  const token = await getAPIToken();
  if(token){
    const headers = {
      'authorization': 'Bearer '+ token,
      'Content-Type': 'application/json'
    };

    const request = {
      method: method.toUpperCase(),
      headers
    };

    if (data) {
      request.body = JSON.stringify(data);
    }

    return await fetch(endpoint, request).then((response) => {
      if (response.status === 401) {
        return Promise.reject(401);
      }
      return response.json();
    }).then(
      (data) => {
        dispatch({ type: dispatchTypes.success, data });
      }
    ).catch((error) => {
      dispatch({
        type: dispatchTypes.error,
        error
      });
    });
  }else{
    alert('API Authentication Failed.');
  }

};

export async function getAPIToken(){
  const apiToken = localStorage.getItem('api_token');
  if(apiToken){
    const apiTokenObj = JSON.parse(apiToken);
    const currentTimestamp = Math.round(new Date().getTime() / 1000); //get timestamp for now

    if(apiTokenObj['expiresIn'] && currentTimestamp < apiTokenObj['expiresIn']){
      return apiTokenObj['token'];
    }
  }
  return fetchAPIToken();

}

export async function fetchAPIToken() {
  const headers = {
    'Content-Type': 'application/json'
  };
  const request = {
    method: 'POST',
    headers
  };
  const endpoint = `${PROXY_API_BASE_URL}/auth/login`;
  const data = {
    username: DO_API_USERNAME,
    password: DO_API_PASSWORD,
  };

  request.body = JSON.stringify(data);
  const response = await fetch(endpoint, request);
  if(response.status === 200){
    const data = await response.json();
    if(data['token']){
      localStorage.setItem('api_token', JSON.stringify(data));
      return data['token'];
    }
  }
  return false;
}



export function fetchAllSites() {
  return {
    type: LOCATEINV_FETCH_SITES_INIT,
  };
}

export function fetchAllInventory() {
  return {
    type: LOCATEINV_FETCH_INVENTORY_INIT,
  };
}

export function fetchPOTO(site) {
  return {
    type: LOCATEINV_FETCH_PO_TO_INIT,
    site
  };
}

export function receiveVendorReceipt(id, type) {
  return {
    type: LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_INIT,
    payload: {
      id,
      type
    }
  };
}

export function locateInvClearUOTO() {
  return {
    type: LOCATEINV_CLEAR_UO_TO
  };
}

export const locateInvCreateUO = (site_id, xero_project_name) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-useorder?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_UO_START,
      success: LOCATEINV_CREATE_UO_SUCCESS,
      error: LOCATEINV_CREATE_UO_ERROR
    },
    'post',
    {
      site_id,
      xero_project_name
    }
  );
};

export const locateInvCreateUOLine = (useorder_id, lines) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-useorder-line?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_UO_LIST_START,
      success: LOCATEINV_CREATE_UO_LIST_SUCCESS,
      error: LOCATEINV_CREATE_UO_LIST_ERROR
    },
    'post',
    {
      useorder_id,
      lines
    }
  );
};

export const locateInvIssueUO = (useorder_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/issue-useorder/${useorder_id}?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_ISSUE_UO_START,
      success: LOCATEINV_ISSUE_UO_SUCCESS,
      error: LOCATEINV_ISSUE_UO_ERROR
    },
    'post'
  );
};

export const locateInvCreateTO1 = (from_site_id, to_site_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-transferorder?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_TO1_START,
      success: LOCATEINV_CREATE_TO1_SUCCESS,
      error: LOCATEINV_CREATE_TO1_ERROR
    },
    'post',
    {
      from_site_id,
      to_site_id
    }
  );
};

export const locateInvCreateTO2 = (from_site_id, to_site_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-transferorder?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_TO2_START,
      success: LOCATEINV_CREATE_TO2_SUCCESS,
      error: LOCATEINV_CREATE_TO2_ERROR
    },
    'post',
    {
      from_site_id,
      to_site_id
    }
  );
};

export const locateInvCreateTO3 = (from_site_id, to_site_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-transferorder?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_TO3_START,
      success: LOCATEINV_CREATE_TO3_SUCCESS,
      error: LOCATEINV_CREATE_TO3_ERROR
    },
    'post',
    {
      from_site_id,
      to_site_id
    }
  );
};

export const locateInvCreateTO1Line = (transferorder_id, lines) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-transferorder-line?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_TO1_LIST_START,
      success: LOCATEINV_CREATE_TO1_LIST_SUCCESS,
      error: LOCATEINV_CREATE_TO1_LIST_ERROR
    },
    'post',
    {
      transferorder_id,
      lines
    }
  );
};

export const locateInvCreateTO2Line = (transferorder_id, lines) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-transferorder-line?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_TO2_LIST_START,
      success: LOCATEINV_CREATE_TO2_LIST_SUCCESS,
      error: LOCATEINV_CREATE_TO2_LIST_ERROR
    },
    'post',
    {
      transferorder_id,
      lines
    }
  );
};

export const locateInvCreateTO3Line = (transferorder_id, lines) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/create-transferorder-line?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_CREATE_TO3_LIST_START,
      success: LOCATEINV_CREATE_TO3_LIST_SUCCESS,
      error: LOCATEINV_CREATE_TO3_LIST_ERROR
    },
    'post',
    {
      transferorder_id,
      lines
    }
  );
};

export const locateInvIssueTO1 = (transferorder_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/issue-transferorder/${transferorder_id}?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_ISSUE_TO1_START,
      success: LOCATEINV_ISSUE_TO1_SUCCESS,
      error: LOCATEINV_ISSUE_TO1_ERROR
    },
    'post'
  );
};

export const locateInvIssueTO2 = (transferorder_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/issue-transferorder/${transferorder_id}?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_ISSUE_TO2_START,
      success: LOCATEINV_ISSUE_TO2_SUCCESS,
      error: LOCATEINV_ISSUE_TO2_ERROR
    },
    'post'
  );
};

export const locateInvIssueTO3 = (transferorder_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/issue-transferorder/${transferorder_id}?env=${state.environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_ISSUE_TO3_START,
      success: LOCATEINV_ISSUE_TO3_SUCCESS,
      error: LOCATEINV_ISSUE_TO3_ERROR
    },
    'post'
  );
};


export const locateInvFetchInventory = (environment, site_id) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/inventory/${site_id}?env=${environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_FETCH_INVENTORY_START,
      success: LOCATEINV_FETCH_INVENTORY_SUCCESS,
      error: LOCATEINV_FETCH_INVENTORY_ERROR
    }
  );
};

export const locateInvFetchSites = (environment) => (dispatch, getState) => {
  const state = getState();
  return fetchRequest(
    `${PROXY_API_BASE_URL}/locate/all-sites?env=${environment.name}`,
    dispatch,
    state,
    {
      start: LOCATEINV_FETCH_SITES_START,
      success: LOCATEINV_FETCH_SITES_SUCCESS,
      error: LOCATEINV_FETCH_SITES_ERROR
    }
  );
};

export const locateInvFetchDropShipPOs = () => (dispatch, getState) => {
    const state = getState();
    const endpoint = `${PROXY_API_BASE_URL}/locate/dropship-po?env=${state.environment.name}`;
    return fetchRequest(
      endpoint,
      dispatch,
      state,
      {
        start: LOCATEINV_FETCH_DROPSHIP_PO_START,
        success: LOCATEINV_FETCH_DROPSHIP_PO_SUCCESS,
        error: LOCATEINV_FETCH_DROPSHIP_PO_ERROR
      }
    );
};


export const locateConfirmDropShipPOs = (order_id, receipts, tracking_number) => (dispatch, getState) => {
  const state = getState();
  const endpoint = `${PROXY_API_BASE_URL}/locate/confirm-dropship-po?env=${state.environment.name}`;
  return fetchRequest(
    endpoint,
    dispatch,
    state,
    {
      start: LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_START,
      success: LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_SUCCESS,
      error: LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_ERROR
    },
    'post',
    {
      order_id: order_id,
      receipts: receipts,
      tracking_number: tracking_number
    }
  );
};
export const locateInvFetchPOTO = (site) => {
  return (dispatch, getState) => {
    const state = getState();
    return fetchRequest(
      `${PROXY_API_BASE_URL}/locate/po-to?siteId=${site.id}&env=${state.environment.name}`,
      dispatch,
      state,
      {
        start: LOCATEINV_FETCH_PO_TO_START,
        success: LOCATEINV_FETCH_PO_TO_SUCCESS,
        error: LOCATEINV_FETCH_PO_TO_ERROR
      }
    );
  };
};

export const locateInvReceiveVendorReceipt = (id, type) => {
  return (dispatch, getState) => {
    const state = getState();
    return fetchRequest(
      `${PROXY_API_BASE_URL}/locate/po-to/vr-receive/${type}/${id}?env=${state.environment.name}`,
      dispatch,
      state,
      {
        start: LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_START,
        success: LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_SUCCESS,
        error: LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_ERROR
      },
      'post'
    );
  }
};

export function createVendorAndVendorSiteLinkWithXero(trackingCategory, vendorName) {
  return (dispatch, getState) => {
    const state = getState();
    return fetchRequest(
      `${PROXY_API_BASE_URL}/locate/create-vendor?env=${state.environment.name}`,
      dispatch,
      state,
      {
        start: LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_START,
        success: LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_SUCCESS,
        error: LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_ERROR
      },
      'post',
      {
        trackingCategory,
        vendorName
      }
    );
  };
}

export function fetchEnterpriseProjectDataActionCreator() {
  return (dispatch, getState) => {
    const state = getState();
    return fetchRequest(
      `${PROXY_API_BASE_URL}/locate/enterprise-project-data?env=${state.environment.name}`,
      dispatch,
      state,
      {
        start: LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_START,
        success: LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_SUCCESS,
        error: LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_ERROR
      }
    );
  };
}

export function linkVendorSiteToTrackingCategory(trackingCategory, site, newVendor, currentVendor) {
  return (dispatch, getState) => {
    const state = getState();
    return fetchRequest(
      `${PROXY_API_BASE_URL}/locate/link-vendor-site?env=${state.environment.name}`,
      dispatch,
      state,
      {
        start: LOCATEINV_LINK_VENDOR_SITE_WITH_TC_START,
        success: LOCATEINV_LINK_VENDOR_SITE_WITH_TC_SUCCESS,
        error: LOCATEINV_LINK_VENDOR_SITE_WITH_TC_ERROR
      },
      'post',
      {
        trackingCategory,
        site,
        newVendor,
        currentVendor
      }
    );
  };
}

