import React, {useEffect, useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import SiteInventoryDataTable from "./SiteInventoryDataTable";

import './index.css';
import UoToCreateModal from "./UoToCreateModal";
import {locateInvClearUOTO, locateInvFetchInventory} from "../../../redux/actions/locateInv";
import {useDispatch} from "react-redux";
import {Link as RouterLink} from "react-router-dom";

const SiteInventoryTool = ({site, locateInv, environment}) => {
  const dispatch = useDispatch();
  const {sites, vendors, apiFieldName, inventory, potoFetchingEnd} = locateInv;
  const [TOLocation1, setTOLocation1] = useState(null);
  const [TOLocation2, setTOLocation2] = useState(null);
  const [TOLocation3, setTOLocation3] = useState(null);
  const [locationVisibility, setLocationVisibility] = useState({l1: false, l2: false, l3: false});
  const [products, setProducts] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [xeroProject, setXeroProject] = useState(false);

  const createUoToHandler = () => {
    setShowCreateModal(true);
  }

  const closeUoToModalHandler = () => {
    const {
      createTo1,
      createTo1Issue,
      createTo2,
      createTo2Issue,
      createTo3,
      createTo3Issue,
      createUo,
      createUoIssue
    } = locateInv;

    // if(products) {
    //   const newProd = products.map((el,i)=>{
    //     const newEl = {...el};
    //     delete newEl['UoToQty'];
    //     return newEl;
    //   });
    //
    //   setProducts(newProd);
    // }

    /** refresh page on close create UO/TO modal, if issue is success */
    if(createTo1Issue.order !== false || createTo2Issue.order !== false || createTo2Issue.order !== false || createUoIssue.order !== false) {
      document.location.reload();
    }

    /** clear UO/TO data */
    if(createTo1.order !== false || createTo2.order !== false || createTo2.order !== false || createUo.order !== false) {
      dispatch(locateInvClearUOTO());
    }

    setShowCreateModal(false);
  }

  useEffect(() => {
    setLocationVisibility({l1: !!TOLocation1, l2: !!TOLocation2, l3: !!TOLocation3})
  }, [TOLocation1, TOLocation2, TOLocation3])

  useEffect(() => {
    if (inventory && inventory.length > 0) {
      setProducts(inventory);
    } else {
      setProducts([])
    }
  }, [inventory])

  useEffect(() => { // dispatch after PoTo ready
    if (potoFetchingEnd) {
        dispatch(locateInvFetchInventory(environment,site.id));
    }
  }, [potoFetchingEnd])

  useEffect(() => {
    setProducts([]);
    const {vendor_id} = site;
    let newXeroProject = false;
    if(vendor_id && vendors && apiFieldName) {
      const foundVendor = vendors.find(el=>el.id===vendor_id)
      if(foundVendor && foundVendor[apiFieldName]) {
        if(foundVendor[apiFieldName].name) {
          newXeroProject = foundVendor[apiFieldName];
        }
      }
    }
    setXeroProject(newXeroProject);
  }, [site])

  const DetectXeroProject = () => {
    if (xeroProject) {
      return (
        <div className="PageGreyRow" style={{border:"2px solid green"}}>
          <p>This Project’s Site is Conencted to This Xero Project: <b>{xeroProject.name}</b>. Confirm that this is
            correct before issuing any Use Orders.</p>
        </div>
      )
    } else {
      return (
        <div className="PageGreyRow" style={{border:"2px solid red"}}>
          <p><b>This Project’s Site is Not Correctly Connected to a Xero Project.</b> Ask someone to fix it in Locate
            or using the <RouterLink to="/app/xero-enterprise-project">Enterprise Project Tool</RouterLink>.
            You can not create any Use Orders until this issue has been fixed. You can create TOs however.</p>
        </div>
      )
    }
  }

  return (
    <div style={{paddingBottom: 15}}>
      <h2>Site Inventory and UO/TO Closeout Tool</h2>
      <div>
        <b>Instructions:</b><br/>
        <ol>
          <li>Once project is complete, carefully count what parts you have left.</li>
          <li>If you haven’t already, make sure that everything listed as “Inbound Stock” above has been received before
            continuing.
          </li>
          <li>Decide what you want to do with the extra stock. This tool allows you to choose up to 3 locations to
            Transfer it to. You might be send some stock to HQ using UPS, and drive/fly some stock to another project or
            your home.. Select one or more locations for the extra stock from the dropdowns below.
          </li>
          <li>Now enter what you want to TO to each of the sites you’ve selected.</li>
          <li>You can optionally assign the remainder of the stock to be used in a Use Order (UO). Please note: Use
            Orders can’t be undone once they’re issued, so you need to make sure you have everything counted correctly.
          </li>
          <li>Once you’ve assigned the stock, the tool will walk you through creating the TOs and UOs.</li>
        </ol>
      </div>
      <DetectXeroProject/>
      <div style={{display: "flex"}}>
        <Autocomplete
          id="TOLocation1"
          options={sites}
          value={TOLocation1}
          getOptionLabel={option => option.name}
          getOptionSelected={s => TOLocation1 ? s.id === TOLocation1.id : null}
          style={{width: 300, margin: 5}}
          onChange={(_, value) => {
            setTOLocation1(value)
          }}
          renderInput={params => (
            <TextField {...params} label="TO Location 1" variant="outlined" margin="normal"/>
          )}
        />
        <Autocomplete
          id="TOlocation2"
          options={sites}
          value={TOLocation2}
          getOptionLabel={option => option.name}
          getOptionSelected={s => TOLocation2 ? s.id === TOLocation2.id : null}
          style={{width: 300, margin: 5}}
          onChange={(_, value) => {
            setTOLocation2(value)
          }}
          renderInput={params => (
            <TextField {...params} label="TO Location 2" variant="outlined" margin="normal"/>
          )}
        />

        <Autocomplete
          id="TOlocation3"
          options={sites}
          value={TOLocation3}
          getOptionLabel={option => option.name}
          getOptionSelected={s => TOLocation3 ? s.id === TOLocation3.id : null}
          style={{width: 300, margin: 5}}
          onChange={(_, value) => {
            setTOLocation3(value)
          }}
          renderInput={params => (
            <TextField {...params} label="TO Location 3" variant="outlined" margin="normal"/>
          )}
        />

      </div>
      <SiteInventoryDataTable
        products={products}
        setProducts={setProducts}
        xeroProject={xeroProject}
        environment={environment}
        locationVisibility={locationVisibility}/>
      <div className="PageGreyRow" style={{textAlign: 'right'}}>
        <Button disabled={!products.filter(p => p.UoToQty).length} onClick={createUoToHandler} variant="contained" color="secondary">Create TOs and UOs</Button>
      </div>

      <UoToCreateModal
        environment={environment}
        site={site}
        selectedLocations={{TOLocation1,TOLocation2,TOLocation3}}
        xeroProject={xeroProject}
        products={products.filter(p => p.UoToQty)}
        open={showCreateModal}
        handleCloseMain={closeUoToModalHandler}
      />
    </div>
  )
}

export default SiteInventoryTool
