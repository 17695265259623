import React, {useRef} from 'react';
import {Helmet} from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TableChartIcon from '@material-ui/icons/TableChart';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import {Route, Link as RouterLink, withRouter /*Redirect, useLocation*/} from "react-router-dom";
// import WorkOutlineIcon from '@material-ui/icons/WorkOutline';

import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import AppsIcon from '@material-ui/icons/Apps';
import { GoogleLogout } from 'react-google-login';

import InboundStockTool from './InboundStock/InboundStock';
import XeroProjectCreate from './XeroLocate/XeroProjectCreate';
import Environment from "./components/Environment";
import {GOOGLE_CLIENT_ID} from "../constants/settings";
import {GOOGLE_AUTHENTICATION_TOKEN_EXPIRE} from "../constants/ActionTypes";
import {HomeInboundStock} from "./InboundStock/HomeInboundStock";
import {HomeDropShipTracking} from "./DropShipTracking/HomeDropShipTracking";
// import ToolCard from "./components/ToolCard";

const drawerWidth = 340;
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  title: {
    flexGrow: 1,
    textAlign: 'left'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbarSecondary: {
    position: 'relative',
  },
  mainContainer: {
    width: '100%',
    margin: '0 20px'
  },
  toolCardsWrapper: {
    display: 'flex',
    margin: '30px',
    '& a': {
      display: 'block',
      margin: 20
    }
  },
  toolIcon: {
    width: '60px',
    marginRight: '15px'
  }
}));

const availableTools = [
  {
    image: 'cube-outline.svg',
    route: '/app/inbound-stock-home',
    title: 'Inbound Stock Tool',
    body: 'Check stock and receive receipt'
  },
  {
    image: 'briefcase-outline.svg',
    route: '/app/xero-enterprise-project',
    title: 'Enterprise Project tool',
    body: 'Creates a new Project in Xero, new Vendor in Locate, Creates new Vendor Site for that Vendor in Locate.'
  },
  {
    image: 'tracking-number.svg',
    route: '/app/dropship-tracking-numbers',
    title: 'Dropship Tracking Number tool',
    body: 'Enables Quickly entering tracking numbers for Drop-Shipped Orders'
  }
];

export function App({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // let location = useLocation();

  const drawerRef = useRef();
  const menuId = 'primary-search-account-menu';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const googleAuth = useSelector(state => state.googleAuth);

  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    dispatch({
      type: GOOGLE_AUTHENTICATION_TOKEN_EXPIRE
    });
  };

  history.listen(() => {
    handleDrawerClose();
  });

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>{googleAuth.user.name}<br />{googleAuth.user.email}</MenuItem>
      <GoogleLogout
        clientId={GOOGLE_CLIENT_ID}
        render={renderProps => (<MenuItem disabled={renderProps.disabled} onClick={renderProps.onClick}>Logout</MenuItem>)}
        onLogoutSuccess={handleLogout}
      />
    </Menu>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const title = 'Waveform Tools';

  return (<div className={classes.root}>
    <Helmet>
      <title>{ title }</title>
    </Helmet>
    <CssBaseline />
    <AppBar
        position="fixed"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title} noWrap>
            Waveform Tools
          </Typography>
          <Environment />
          <div className={classes.toolbarSecondary}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar src={googleAuth.user.image} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        ref={drawerRef}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          <ListItem >
              <ListItemIcon><AppsIcon /></ListItemIcon>
              <ListItemText><RouterLink to="/app">Home</RouterLink></ListItemText>
          </ListItem>
          <ListItem >
              <ListItemIcon><TableChartIcon /></ListItemIcon>
              <ListItemText><RouterLink to="/app/inbound-stock-home">Inbound Stock Tool</RouterLink></ListItemText>
          </ListItem>
          <ListItem >
              <ListItemIcon><AddToQueueIcon /></ListItemIcon>
              <ListItemText><RouterLink to="/app/xero-enterprise-project">Enterprise Project Tool</RouterLink></ListItemText>
          </ListItem>
          <ListItem >
            <ListItemIcon>
              <img alt='' style={{height:'24px', width: '24px'}} className={classes.toolIcon} src='/images/tracking-number.svg' /></ListItemIcon>
            <ListItemText><RouterLink to="/app/dropship-tracking-numbers">Dropship Tracking Number tool</RouterLink></ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.mainContainer} style={{marginTop: '60px'}}>
        <Route exact path={"/app"}>
          <div className={classes.toolCardsWrapper}>
            <List>
            {availableTools.map((tool, i) => (
              <RouterLink style={{ textDecoration: 'none' }} key={i} to={tool.route}>
                <ListItem>
                  <ListItemIcon><img alt='' className={classes.toolIcon} src={`/images/${tool.image}`} /></ListItemIcon>
                  <ListItemText
                    primary={tool.title}
                    secondary={tool.body}
                  >{tool.title}</ListItemText>
                </ListItem>
              </RouterLink>
            ))}
            </List>
          </div>
        </Route>
        <Route path="/app/inbound-stock-home">
            <HomeInboundStock />
        </Route>
        <Route path="/app/inbound-stock-tool">
            <InboundStockTool />
        </Route>
        <Route path="/app/xero-enterprise-project">
            <XeroProjectCreate />
        </Route>
        <Route path="/app/dropship-tracking-numbers">
            <HomeDropShipTracking />
        </Route>
      </main>
    {/* {!location.hash && <Redirect to="/app/inbound-stock-tool" />} */}
    </div>);
}

export default withRouter(App);
