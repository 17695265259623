import * as ActionTypes from '../../constants/ActionTypes';

const initialState = {
  loading: false,
  messages: [],
  site: '',
  progress: 0,
  error: null,
  allTC: [],
  xeroProjects: [],
};

const searchRegex = new RegExp('Project:', 'gi');

const actionsMap = {
  [ActionTypes.XERO_PROJECT_CREATE_SUCCESS](state, data) {
    return Object.assign({}, state, {
      messages: state.messages.concat(data.message),
      progress: data.progress,
      link: data.link,
      site: data.site,
      vendor: data.vendor,
      loading: false
    });
  },
  [ActionTypes.XERO_PROJECT_CREATE_INITIALIZE]() {
    return initialState;
  },
  [ActionTypes.XERO_PROJECT_CREATE_START_LOADING]() {
    return { ...initialState, loading: true };
  },
  [ActionTypes.XERO_PROJECT_CREATE_ERROR](state, data) {
    return Object.assign({}, state, { error: data.error, loading: false });
  },
  [ActionTypes.XERO_PROJECT_CREATE_MESSAGE](state, data) {
    return Object.assign({}, state, {
      messages: state.messages.concat(data.message),
      progress: data.progress,
      loading: true
    });
  },
  [ActionTypes.XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_STRAT](state) {
    return {
      ...state,
      loading: true
    };
  },
  [ActionTypes.XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_SUCCESS](state, action) {
    const allTC = action.data.map(name => ({ name, source: 'Xero' }));
    return {
      ...state,
      allTC,
      xeroProjects: allTC.filter(tc => tc.name.search(searchRegex) >= 0),
      loading: false
    };
  },
  [ActionTypes.XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_ERROR](state, action) {
    return {
      ...state,
      loading: false,
      error: action.error || null
    };
  }
};


export default function xeroLocate(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
}

