import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import styles from './ToPoDataTable.css';

export default class TrackingNumbers extends Component {
  static propTypes = {
    order: PropTypes.object,
  };

  render() {
    const { order } = this.props;
    if (order.carrier && order.carrier.tracking_url) {
      return (<a href={`${order.carrier.tracking_url}${order.trackingNumber}`} rel="noopener noreferrer" target={'_blank'}>{order.trackingNumber}</a>);
    } else {
      return (<div>{order.trackingNumber}</div>);
    }
    // return (<div className={styles.trackingNumbersDataCell}>{(order.receipts && order.receipts.length) ? order.receipts.filter(r => r.tracking_number).map(r => r.tracking_number).join(', ') : ''}</div>);
  }
}
