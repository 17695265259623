import randomcolor from "randomcolor";

// eslint-disable-next-line class-methods-use-this
const transformPurchaseOrders = (orders) => {
  const newOrders = [];
  orders.forEach((o) => {
    const color = randomcolor({luminosity: 'light', format: 'rgba', alpha: 0.14});
    let trackingNumber;

    if (o.products && o.products.length) {
      let pickupLine;
      if (o.type === 'PurchaseOrder') {
        trackingNumber = o.receipts.map(r => r.tracking_number).filter(tn => tn).join(', ');
      } else {
        pickupLine = o.pickuplines.find(pl =>
          pl.carton.contents.find(item =>
            item.part_id === o.products[0].partId));
        if (pickupLine) {
          trackingNumber = pickupLine.carton.tracking_number;
        }
      }


    } else {
      trackingNumber = '';
    }
    const order = {
      ...o,
      color,
      statusName: o.status.name,
      trackingNumber
    };
    newOrders.push(order);
  });

  return newOrders;
}

export default transformPurchaseOrders
