import React, { Component } from 'react';
import { DataGrid, ToolbarOptions } from 'tubular-react';
import { ColumnModel } from 'tubular-common';
import LinearProgress from '@material-ui/core/LinearProgress';
import { TableCell, TableRow } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {locateInvFetchPOTO} from '../../redux/actions/locateInv';
import TrackingNumbers from './TrackingNumbers';

import styles from './ToPoDataTable.css';
import ConfirmReceiveDialog from './ConfirmReceiveDialog';
import transformOrders from "../../utils/transformOrder";

const columns = [
  new ColumnModel('id', {
    Sortable: true,
    Label: 'ID'
  }),
  new ColumnModel('type', {
    Sortable: true,
    Label: 'Type'
  }),
  new ColumnModel('number', {
    Sortable: true,
    Searchable: true,
    Label: 'Number'
  }),
  new ColumnModel('status', {
    Visible: false
  }),
  new ColumnModel('statusName', {
    Sortable: true,
    Label: 'Status',
    searchable: true
  }),
  new ColumnModel('productLineStatusName', {
    Label: 'Item Status',
    Sortable: true,
    searchable: true
  }),
  new ColumnModel('productLineStatus', {
    Visible: false
  }),
  new ColumnModel('trackingNumber', {
    Sortable: true,
    Searchable: false,
    Label: 'TrackingNum'
  }),
  new ColumnModel('receipts', {
    Visible: false
  }),
  new ColumnModel('lines', {
    Searchable: false,
    Visible: false
  }),
  new ColumnModel('productPartNumber', {
    Label: 'Product Number',
    Sortable: true,
    Searchable: true
  }),
  new ColumnModel('productPartName', {
    Label: 'Product Name',
    Sortable: true,
    Searchable: true
  }),
  new ColumnModel('productQty', {
    Label: 'Qty'
  }),
  new ColumnModel('productPartId', {
    Visible: false
  }),
  new ColumnModel('pickuplines', {
    Visible: false
  }),
  new ColumnModel('color', {
    Visible: false
  }),
  new ColumnModel('carrier', {
    Visible: false
  }),
];

export class ToPoDataTable extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    locateInv: PropTypes.object.isRequired,
    site: PropTypes.object,
    environment: PropTypes.object.isRequired
  };

  componentDidUpdate(prevProps) {
    const { dispatch, site } = this.props;
    if (prevProps.site !== site && site && site.id) {
      dispatch(locateInvFetchPOTO(site));
    }
    if(prevProps.locateInv.vendorReceiptId !== this.props.locateInv.vendorReceiptId) {
      dispatch(locateInvFetchPOTO(site));
    }
  }

  render() {
    const { orders, loading } = this.props.locateInv;
    const { url } = this.props.environment;
    const { site } = this.props;

    const newOrders = orders && orders.length ? transformOrders(orders) : [];

    return (
      <div>
        <div hidden={!loading} className={styles.loadingWrapper}>
          <LinearProgress hidden={!loading} />
        </div>
        <DataGrid
          columns={columns}
          sortColumn={(a, b) => { console.log(a, b); }}
          dataSource={site ? newOrders : []}
          gridName="Purchase and Transfer Orders"
          toolbarOptions={new ToolbarOptions({
            itemsPerPage: 1000,
            bottomPager: false,
            topPager: false
          })}
          rowComponent={({ row: order }) => {
            let vendorProducts;
            if(order.productLineStatus && order.productLineStatus.id === 60) {
              vendorProducts = orders.find(o => o.id === order.id)?.products.filter(p => p.status.id === 60);
            }
            return (
              <TableRow
                hover key={order.id} style={{ backgroundColor: order.color }}
              >
                <TableCell size="small">{order.id}</TableCell>
                <TableCell size="small">{order.type === 'PurchaseOrder' ? 'PO' : 'TO'}</TableCell>
                <TableCell size="small" className={styles.numberColumn}>
                  <a href={`${url}/app#/${order.type === 'PurchaseOrder' ? 'purchaseorder' : 'transferorder'}/${order.id}/profile`} rel="noopener noreferrer" target={'_blank'}>{order.number}</a>
                </TableCell>
                <TableCell size="small">{order.status ? order.status.name : ''}</TableCell>
                <TableCell size="small" className={styles.numberColumn}>
                  {order.productLineStatus && order.productLineStatus.name && <small><b>{order.productLineStatus.name}</b></small>}
                  {order.productLineStatus && (order.productLineStatus.id === 60 || (site.sitetype.name === 'Vendor' && order.productLineStatus.id === 42)) &&
                    <ConfirmReceiveDialog order={order} url={url} products={vendorProducts || []} />
                  }
                </TableCell>
                <TableCell size="small"><TrackingNumbers order={order} /></TableCell>
                <TableCell size="small"><a href={`${url}/app#/part/${order.productPartId}/`} rel="noopener noreferrer" target={'_blank'}>{order.productPartNumber}</a></TableCell>
                <TableCell size="small"><a href={`${url}/app#/part/${order.productPartId}/`} rel="noopener noreferrer" target={'_blank'}>{order.productPartName}</a></TableCell>
                <TableCell size="small">{order.productQty}</TableCell>
              </TableRow>
            );
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  locateInv: state.locateInv,
  environment: state.environment
});

export default connect(mapStateToProps)(ToPoDataTable);
