import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk'
import loggerMiddleware from './logger';
import rootReducer from '../reducers';

export default function configureStore(preloadedState) {
	const middlewares = [loggerMiddleware, thunkMiddleware];
	const middlewareEnhancer = applyMiddleware(...middlewares);

	const enhancers = [middlewareEnhancer];
	const composedEnhancers = compose(...enhancers);

	const store = createStore(rootReducer, preloadedState, composedEnhancers);

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
	}

	return store
}
