import * as ActionTypes from '../../constants/ActionTypes';
import { SITE_TYPE_VENDOR_ID } from '../../constants/settings';
import {LOCATEINV_CLEAR_UO_TO} from "../../constants/ActionTypes";
import {useDispatch} from "react-redux";
import {locateInvFetchDropShipPOs} from "../actions/locateInv";

const initialState = {
  loading: false,
  sessionToken: null,
  currentSalesOrder: null,
  vendorReceiptId: null,
  trackingCategories: [],
  sites: [],
  dropshipPo: [],
  dropshipPoConfirmed: false,
  sitesVendor: [],
  inventory: [],
  potoFetchingEnd: false,
  orders: [],
  createUo: {error:false, loading: false, order:false},
  createUoList: {error:false, loading: false, order:false},
  createUoIssue: {error:false, loading: false, order:false},
  createTo1: {error:false, loading: false, order:false},
  createTo1List: {error:false, loading: false, order:false},
  createTo1Issue: {error:false, loading: false, order:false},
  createTo2: {error:false, loading: false, order:false},
  createTo2List: {error:false, loading: false, order:false},
  createTo2Issue: {error:false, loading: false, order:false},
  createTo3: {error:false, loading: false, order:false},
  createTo3List: {error:false, loading: false, order:false},
  createTo3Issue: {error:false, loading: false, order:false},
  statuses: [],
  sitesNames: [],
  sitesIndex: {},
  vendorsIndex: {},
  vendors: [],
  site: null,
  vendor: null,
  error: null
};

export const searchRegex = new RegExp('Project:', 'gi');

const actionsMap = {
  [ActionTypes.LOCATEINV_CLEAR_UO_TO](state) {
    return {
      ...state,
      createUo: {error:false, loading: false, order:false},
      createUoList: {error:false, loading: false, order:false},
      createUoIssue: {error:false, loading: false, order:false},
      createTo1: {error:false, loading: false, order:false},
      createTo1List: {error:false, loading: false, order:false},
      createTo1Issue: {error:false, loading: false, order:false},
      createTo2: {error:false, loading: false, order:false},
      createTo2List: {error:false, loading: false, order:false},
      createTo2Issue: {error:false, loading: false, order:false},
      createTo3: {error:false, loading: false, order:false},
      createTo3List: {error:false, loading: false, order:false},
      createTo3Issue: {error:false, loading: false, order:false}
    };
  },  [ActionTypes.LOCATEINV_CREATE_UO_START](state) {
    return {
      ...state,
      createUo: {
        error:false,
        loading:true,
        order:false
      },
      createUoList: {
        error:false,
        loading:false,
        order:false
      },
      createUoIssue: {
        error:false,
        loading:false,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_UO_SUCCESS](state, payload) {

    return {
      ...state,
      createUo: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_UO_ERROR](state, payload) {
    return {
      ...state,
      createUo: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_CREATE_UO_LIST_START](state) {
    return {
      ...state,
      createUoList: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_UO_LIST_SUCCESS](state, payload) {

    return {
      ...state,
      createUoList: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_UO_LIST_ERROR](state, payload) {
    return {
      ...state,
      createUoList: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_UO_START](state) {
    return {
      ...state,
      createUoIssue: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_UO_SUCCESS](state, payload) {

    return {
      ...state,
      createUoIssue: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_UO_ERROR](state, payload) {
    return {
      ...state,
      createUoIssue: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },



  [ActionTypes.LOCATEINV_CREATE_TO1_START](state) {
    return {
      ...state,
      createTo1: {
        error:false,
        loading:true,
        order:false
      },
      createTo1List: {
        error:false,
        loading:false,
        order:false
      },
      createTo1Issue: {
        error:false,
        loading:false,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO1_SUCCESS](state, payload) {

    return {
      ...state,
      createTo1: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO1_ERROR](state, payload) {
    return {
      ...state,
      createTo1: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO1_LIST_START](state) {
    return {
      ...state,
      createTo1List: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO1_LIST_SUCCESS](state, payload) {

    return {
      ...state,
      createTo1List: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO1_LIST_ERROR](state, payload) {
    return {
      ...state,
      createTo1List: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO1_START](state) {
    return {
      ...state,
      createTo1Issue: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO1_SUCCESS](state, payload) {

    return {
      ...state,
      createTo1Issue: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO1_ERROR](state, payload) {
    return {
      ...state,
      createTo1Issue: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO2_START](state) {
    return {
      ...state,
      createTo2: {
        error:false,
        loading:true,
        order:false
      },
      createTo2List: {
        error:false,
        loading:false,
        order:false
      },
      createTo2Issue: {
        error:false,
        loading:false,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO2_SUCCESS](state, payload) {

    return {
      ...state,
      createTo2: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO2_ERROR](state, payload) {
    return {
      ...state,
      createTo2: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO2_LIST_START](state) {
    return {
      ...state,
      createTo2List: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO2_LIST_SUCCESS](state, payload) {

    return {
      ...state,
      createTo2List: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO2_LIST_ERROR](state, payload) {
    return {
      ...state,
      createTo2List: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO2_START](state) {
    return {
      ...state,
      createTo2Issue: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO2_SUCCESS](state, payload) {

    return {
      ...state,
      createTo2Issue: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO2_ERROR](state, payload) {
    return {
      ...state,
      createTo2Issue: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO3_START](state) {
    return {
      ...state,
      createTo3: {
        error:false,
        loading:true,
        order:false
      },
      createTo3List: {
        error:false,
        loading:false,
        order:false
      },
      createTo3Issue: {
        error:false,
        loading:false,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO3_SUCCESS](state, payload) {

    return {
      ...state,
      createTo3: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO3_ERROR](state, payload) {
    return {
      ...state,
      createTo3: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO3_LIST_START](state) {
    return {
      ...state,
      createTo3List: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO3_LIST_SUCCESS](state, payload) {

    return {
      ...state,
      createTo3List: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_CREATE_TO3_LIST_ERROR](state, payload) {
    return {
      ...state,
      createTo3List: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO3_START](state) {
    return {
      ...state,
      createTo3Issue: {
        error:false,
        loading:true,
        order:false
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO3_SUCCESS](state, payload) {

    return {
      ...state,
      createTo3Issue: {
        error: false,
        loading: false,
        order:payload.data
      },
    };
  },
  [ActionTypes.LOCATEINV_ISSUE_TO3_ERROR](state, payload) {
    return {
      ...state,
      createTo3Issue: {
        error: payload.error,
        loading: false,
        order:false
      }
    };
  },



  [ActionTypes.LOCATEINV_FETCH_SITES_START](state) {
    return {
      ...state,
      loading: true
    };
  },
  [ActionTypes.LOCATEINV_FETCH_INVENTORY_START](state) {
    return {
      ...state,
      loading: true
    };
  },
  [ActionTypes.LOCATEINV_FETCH_SITES_SUCCESS](state, payload) {
    const sitesNames = payload.data.map(s => ({ source: 'LocateInv', name: s.name, id: s.id }) );
    const sitesIndex = {};
    sitesNames.forEach(site => {
      Object.assign(sitesIndex, {[site.name]: site});
    });

    return {
      ...state,
      error: false,
      loading: false,
      sites: payload.data,
      vendorProjects: sitesNames.filter(site => site.name.search(searchRegex) >= 0),
      sitesIndex,
      vendors: [],
      sitesNames,
    };
  },
  [ActionTypes.LOCATEINV_FETCH_INVENTORY_SUCCESS](state, payload) {
    return {
      ...state,
      error: false,
      loading: false,
      inventory: payload.data
    };
  },
  [ActionTypes.LOCATEINV_FETCH_VENDORS_SUCCESS](state, payload) {

    const vendorsIndex = {};
    payload.data.forEach((vendor) => {
      Object.assign(vendorsIndex, { [vendor.name]: vendor });
    });

    return {
      ...state,
      loading: false,
      error: false,
      vendors: payload.data.filter(vendor => vendor.name.search(searchRegex) >= 0),
      vendorsIndex
    };
  },
  [ActionTypes.LOCATEINV_FETCH_SITES_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },
  [ActionTypes.LOCATEINV_FETCH_INVENTORY_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },
  [ActionTypes.LOCATEINV_FETCH_PO_TO_START](state) {
    return {
      ...state,
      potoFetchingEnd: false,
      loading: true
    };
  },
  [ActionTypes.LOCATEINV_FETCH_PO_TO_SUCCESS](state, payload) {
    return {
      ...state,
      loading: false,
      potoFetchingEnd: true,
      orders: payload.data
    };
  },
  [ActionTypes.LOCATEINV_FETCH_PO_TO_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      potoFetchingEnd: false,
      error: payload.error
    };
  },
  [ActionTypes.LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_SUCCESS](state, payload) {
    return {
      ...state,
      vendorReceiptId: payload.data.id
    };
  },
  [ActionTypes.LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_ERROR](state, payload) {
    return {
      ...state,
      error: payload.error
    };
  },
  [ActionTypes.LOCATEINV_FETCH_VENDORS_START](state) {
    return {
      ...state,
      error: false,
      loading: true
    };
  },
  [ActionTypes.LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_START](state) {
    return {
      ...state,
      loading: true,
      error: null
    };
  },
  [ActionTypes.LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_SUCCESS](state, payload) {
    const vendorsIndex = {};
    payload.data.vendors.forEach((vendor) => {
      if (vendor[payload.data.apiFieldName]) {
        const id = vendor[payload.data.apiFieldName].id;
        Object.assign(vendorsIndex, { [id]: vendor });
      }
    });

    const sitesNames = payload.data.sites.map(s => ({ source: 'LocateInv', name: s.name, id: s.id }) );

    const sitesIndex = {};
    payload.data.sites.forEach((site) => {
      if (site.vendor_id) {
        const vendor = payload.data.vendors.find(v => v.id === site.vendor_id );
        if (vendor && vendor[payload.data.apiFieldName]) {
          const id = vendor[payload.data.apiFieldName].id;
          Object.assign(sitesIndex, { [id]: site });
        }
      }
    });

    return {
      ...state,
      vendors: payload.data.vendors, //.filter(vendor => vendor.name.search(searchRegex) >= 0),
      vendorsIndex,
      sitesIndex,
      sitesNames,
      site: null,
      vendor: null,
      // sites: payload.data.sites,
      sites: payload.data.sites.filter(site => (site.sitetype_id === 3 || site.sitetype_id === 6 || site.sitetype_id === 4)),
      sitesVendor: payload.data.sites.filter(site => site.sitetype_id === SITE_TYPE_VENDOR_ID),
      vendorProjects: sitesNames.filter(site => site.name.search(searchRegex) >= 0),
      trackingCategories: payload.data.trackingCategories,
      apiFieldName: payload.data.apiFieldName,
      loading: false,
      error: null
    };
  },
  [ActionTypes.LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },
  [ActionTypes.LOCATEINV_FETCH_VENDORS_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },
  [ActionTypes.LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_START](state) {
    return {
      ...state,
      loading: true,
      error: null
    };
  },

  [ActionTypes.LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_SUCCESS](state, payload) {
    return {
      ...state,
      loading: false,
      error: null,
      site: payload.data
    };
  },

  [ActionTypes.LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },

  [ActionTypes.LOCATEINV_LINK_VENDOR_SITE_WITH_TC_START](state) {
    return {
      ...state,
      loading: true,
      error: null
    };
  },

  [ActionTypes.LOCATEINV_LINK_VENDOR_SITE_WITH_TC_SUCCESS](state, payload) {
    return {
      ...state,
      loading: false,
      error: null,
      vendor: payload.data
    };
  },

  [ActionTypes.LOCATEINV_LINK_VENDOR_SITE_WITH_TC_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      error: payload.error
    };
  },

  // DropShip PO Start

  [ActionTypes.LOCATEINV_FETCH_DROPSHIP_PO_START](state) {
    return {
      ...state,
      potoFetchingEnd: false,
      loading: true
    };
  },
  [ActionTypes.LOCATEINV_FETCH_DROPSHIP_PO_SUCCESS](state, payload) {
    return {
      ...state,
      loading: false,
      potoFetchingEnd: true,
      dropshipPo: payload.data
    };
  },
  [ActionTypes.LOCATEINV_FETCH_DROPSHIP_PO_ERROR](state, payload) {
    return {
      ...state,
      loading: false,
      potoFetchingEnd: false,
      error: payload.error
    };
  },



  [ActionTypes.LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_START](state) {
    return {
      ...state,
      potoFetchingEnd: false,
      loading: true
    };
  },
  [ActionTypes.LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_SUCCESS](state, payload) {
    let poConfirmed = false,
        poError = true;
    if(payload.data.receiptFinished.length > 0 || payload.data.receiptUpdate.length > 0|| payload.data.orderConfirmed.length > 0 ){
      poConfirmed = true;
      poError = false;
    }
    let pos = state.dropshipPo;
    if(payload.data.order_id && payload.data.order_id > 0){
      let order_id = payload.data.order_id;
      let activeOrderIndex = pos.findIndex(elem => elem.id == order_id);
      if(activeOrderIndex >= 0){
        if(pos[activeOrderIndex].products.length){
          pos[activeOrderIndex].products[0].status.is_complete_status = 1;
        }
      }
    }
    return {
      ...state,
      loading: false,
      potoFetchingEnd: true,
      dropshipPo: pos,
      dropshipPoConfirmed: poConfirmed,
      dropshipPoError: poError,
    };
  },
  [ActionTypes.LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_ERROR](state, payload) {
    console.log(payload.error);
    return {
      ...state,
      loading: false,
      potoFetchingEnd: false,
      dropshipPoError: true,
      error: payload.error
    };
  },
  // DropShip PO End
};

export default function locateInv(state = initialState, action) {
  const reduceFn = actionsMap[action.type];
  if (!reduceFn) return state;
  return reduceFn(state, action);
}
