import {combineReducers} from 'redux';

import environment from './env';
import locateInv from './locateInv';
import googleAuth from './googleAuth';
import xeroLocate from './xeroLocate';
import proxy from './proxy';

export default combineReducers({
  environment,
  locateInv,
  googleAuth,
  xeroLocate,
  proxy
});
