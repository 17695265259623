import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import {
  fetchEnterpriseProjectDataActionCreator
} from '../../redux/actions/locateInv';

import DataTable from './ToPoDataTable';
import SiteInventoryTool from "./SiteInventoryTool/";

export class InboundStock extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    locateInv: PropTypes.object.isRequired,
    environment: PropTypes.object.isRequired
  };
  constructor(props) {
    super(props);

    this.state = {
      selectedSite: {
        name: '',
        id: null
      },
      linkCopied: 'Copy Link'
    }
    ;
    this.handleSiteSelect = this.handleSiteSelect.bind(this);
    this.handleCopyLink = this.handleCopyLink.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.environment.name !== this.props.environment.name) {
      const { dispatch } = this.props;
      // dispatch(locateInvFetchSites(this.props.environment));
      dispatch(fetchEnterpriseProjectDataActionCreator());
    }
    const { sites } = this.props.locateInv;
    if(prevProps.locateInv.sites.length !== sites.length) {
      this.getSharedVendorSite();
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    // dispatch(locateInvFetchSites(this.props.environment));
    dispatch(fetchEnterpriseProjectDataActionCreator());
    this.getSharedVendorSite();
  }

  getSharedVendorSite() {
    if (window.location && window.location.hash) {
      const searchParams = new Map();
      const params = window.location.hash.replace('#', '').split(';');
      if (params.length) {
        params.forEach((p) => {
          const matches = p.match(/(.*)\((\d*)\)/i);
          if (matches && matches[1] && matches[2]) {
            searchParams.set(matches[1], matches[2]);
          }
        });
      }
      if (searchParams.get('vendorSite')) {
        const vendorSite = parseInt(searchParams.get('vendorSite'), 10);
        const site = this.getVendorSiteById(vendorSite);
        if (site) {
          this.setState({ selectedSite: site });
        } else {
          this.setState({ selectedSite: {
            name: ''
          } });
        }
      }
    } else {
      this.setState({ selectedSite: { name: '' } });
    }
  }

  getVendorSiteById(id) {
    const { sites } = this.props.locateInv;
    if (sites) {
      return sites.find(s => s.id === id);
    }

    return false;
  }

  handleSiteSelect(event, newValue) {
    this.setState({ selectedSite: newValue });
    window.location.hash = `vendorSite(${newValue.id})`;
  }

  handleCopyLink() {
    const { origin, pathname } = window.location;
    const originalCopyLinkText = this.state.linkCopied.trim();
    if (this.state.selectedSite.id) {
      const customLink = `${origin}${pathname}#vendorSite(${this.state.selectedSite.id})`;

      navigator.clipboard.writeText(customLink).then((a, b) => {
        this.setState({ linkCopied: 'Copied!' });
        setTimeout(() => {
          this.setState({ linkCopied: originalCopyLinkText });
        }, 10000);
      });
    }
  }

  render() {
    const { sites, sitesVendor, loading } = this.props.locateInv;
    const title = 'Inbound Stock Tool';

    return (<div>
      <Helmet>
        <title>{ this.state.selectedSite.name }</title>
      </Helmet>

      <div style={{ display: 'flex', alignItems: 'flex-start'}}>
        <Autocomplete
          id="vendor-site"
          loading={loading}
          options={sitesVendor}
          value={this.state.selectedSite}
          getOptionLabel={option => option.name}
          getOptionSelected={s => s.id === this.state.selectedSite.id }
          style={{ width: 300 }}
          onChange={this.handleSiteSelect}
          renderInput={params => (
            <TextField {...params} label="Vendor Site" margin="normal" fullWidth />
          )}
        />
        {this.state.selectedSite && this.state.selectedSite.id && <Button
          variant="outlined"
          color="primary"
          style={{ margin: '28px' }}
          onClick={this.handleCopyLink}
        >
          {this.state.linkCopied}
        </Button>}
      </div>
      <DataTable site={this.state.selectedSite} />
      {this.state.selectedSite.id &&
      <SiteInventoryTool
        site={this.state.selectedSite}
        locateInv={this.props.locateInv}
        environment={this.props.environment} />
      }
    </div>);
  }
}

const mapStateToProps = state => ({
  locateInv: state.locateInv,
  environment: state.environment
});

export default connect(mapStateToProps)(InboundStock);
