import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GoogleLogin from 'react-google-login';

import { GOOGLE_CLIENT_ID, allowedGoogleDomains, allowedGoogleEmails } from '../../constants/settings';
import {useDispatch, useSelector} from "react-redux";
import {
  GOOGLE_AUTHENTICATION_DOMAIN_ACCESS_DENIED,
  GOOGLE_AUTHENTICATION_SUCCESS,
  GOOGLE_PROFILE_LOADED
} from "../../constants/ActionTypes";
import {Redirect} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '5rem',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px'
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const googleAuth = useSelector(state => state.googleAuth);
  const [error, setError] = useState('');

  const responseGoogle = (response) => {
    if(response.getAuthResponse() && response.getAuthResponse().access_token) {
      const profile = response.getBasicProfile();
      const emailDomain = profile.getEmail().match(/@(.+)/)[1];
      if (
        allowedGoogleDomains.includes(emailDomain) ||
        allowedGoogleEmails.includes(profile.getEmail())) {
          dispatch({
            type:   GOOGLE_AUTHENTICATION_SUCCESS,
            token:  response.getAuthResponse()
          });
          const intProfile = {
            email:  profile.getEmail(),
            name:   profile.getName(),
            id:     profile.getId(),
            image:  profile.getImageUrl()
          };
          dispatch({
            type:   GOOGLE_PROFILE_LOADED,
            ...intProfile
          });
          localStorage.setItem('token', JSON.stringify(response.getAuthResponse()));
          localStorage.setItem('profile', JSON.stringify(intProfile));
      } else {
          dispatch({
            type: GOOGLE_AUTHENTICATION_DOMAIN_ACCESS_DENIED,
          });
          setError(`Access denied for ${profile.getEmail()}`);
      }
    }
  };

  return ( <Container component="main" maxWidth="xs">
      {googleAuth.token && googleAuth.token.id_token && <Redirect to="/app" />}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Protected Area
        </Typography>
        <p style={{ display: error.length ? 'block' : 'none', color: 'red' }}>{error}</p>
        <form className={classes.form} noValidate onSubmit={(e) => { e.preventDefault(); }}>
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Login with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
          />
        </form>
      </div>
    </Container>
  );
}
