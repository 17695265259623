export const ENV_CHANGE = 'ENV_CHANGE';
export const LOCATEINV_FETCH_INVENTORY_INIT = 'LOCATEINV_FETCH_INVENTORY_INIT';
export const LOCATEINV_FETCH_INVENTORY_START = 'LOCATEINV_FETCH_INVENTORY_START';
export const LOCATEINV_FETCH_INVENTORY_SUCCESS = 'LOCATEINV_FETCH_INVENTORY_SUCCESS';
export const LOCATEINV_FETCH_INVENTORY_ERROR = 'LOCATEINV_FETCH_INVENTORY_ERROR';
export const LOCATEINV_FETCH_SITES_INIT = 'LOCATEINV_FETCH_SITES_INIT';
export const LOCATEINV_FETCH_SITES_START = 'LOCATEINV_FETCH_SITES_START';
export const LOCATEINV_FETCH_SITES_SUCCESS = 'LOCATEINV_FETCH_SITES_SUCCESS';
export const LOCATEINV_FETCH_SITES_ERROR = 'LOCATEINV_FETCH_SITES_ERROR';
export const LOCATEINV_FETCH_PO_TO_INIT = 'LOCATEINV_FETCH_PO_TO_INIT';
export const LOCATEINV_FETCH_PO_TO_START = 'LOCATEINV_FETCH_PO_TO_START';
export const LOCATEINV_FETCH_PO_TO_SUCCESS = 'LOCATEINV_FETCH_PO_TO_SUCCESS';
export const LOCATEINV_FETCH_PO_TO_ERROR = 'LOCATEINV_FETCH_PO_TO_ERROR';
export const LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_INIT = 'LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_INIT';
export const LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_START = 'LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_START';
export const LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_SUCCESS = 'LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_SUCCESS';
export const LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_ERROR = 'LOCATEINV_ORDER_RECEIVE_VENDOR_RECEIPT_ERROR';
export const LOCATEINV_CLEAR_UO_TO = 'LOCATEINV_CLEAR_UO_TO';
export const LOCATEINV_CREATE_UO_START = 'LOCATEINV_CREATE_UO_START';
export const LOCATEINV_CREATE_UO_SUCCESS = 'LOCATEINV_CREATE_UO_SUCCESS';
export const LOCATEINV_CREATE_UO_ERROR = 'LOCATEINV_CREATE_UO_ERROR';
export const LOCATEINV_CREATE_UO_LIST_START = 'LOCATEINV_CREATE_UO_LIST_START';
export const LOCATEINV_CREATE_UO_LIST_SUCCESS = 'LOCATEINV_CREATE_UO_LIST_SUCCESS';
export const LOCATEINV_CREATE_UO_LIST_ERROR = 'LOCATEINV_CREATE_UO_LIST_ERROR';
export const LOCATEINV_ISSUE_UO_START = 'LOCATEINV_ISSUE_UO_START';
export const LOCATEINV_ISSUE_UO_SUCCESS = 'LOCATEINV_ISSUE_UO_SUCCESS';
export const LOCATEINV_ISSUE_UO_ERROR = 'LOCATEINV_ISSUE_UO_ERROR';
export const LOCATEINV_CREATE_TO1_START = 'LOCATEINV_CREATE_TO1_START';
export const LOCATEINV_CREATE_TO1_SUCCESS = 'LOCATEINV_CREATE_TO1_SUCCESS';
export const LOCATEINV_CREATE_TO1_ERROR = 'LOCATEINV_CREATE_TO1_ERROR';
export const LOCATEINV_CREATE_TO1_LIST_START = 'LOCATEINV_CREATE_TO1_LIST_START';
export const LOCATEINV_CREATE_TO1_LIST_SUCCESS = 'LOCATEINV_CREATE_TO1_LIST_SUCCESS';
export const LOCATEINV_CREATE_TO1_LIST_ERROR = 'LOCATEINV_CREATE_TO1_LIST_ERROR';
export const LOCATEINV_ISSUE_TO1_START = 'LOCATEINV_ISSUE_TO1_START';
export const LOCATEINV_ISSUE_TO1_SUCCESS = 'LOCATEINV_ISSUE_TO1_SUCCESS';
export const LOCATEINV_ISSUE_TO1_ERROR = 'LOCATEINV_ISSUE_TO1_ERROR';
export const LOCATEINV_CREATE_TO2_START = 'LOCATEINV_CREATE_TO2_START';
export const LOCATEINV_CREATE_TO2_SUCCESS = 'LOCATEINV_CREATE_TO2_SUCCESS';
export const LOCATEINV_CREATE_TO2_ERROR = 'LOCATEINV_CREATE_TO2_ERROR';
export const LOCATEINV_CREATE_TO2_LIST_START = 'LOCATEINV_CREATE_TO2_LIST_START';
export const LOCATEINV_CREATE_TO2_LIST_SUCCESS = 'LOCATEINV_CREATE_TO2_LIST_SUCCESS';
export const LOCATEINV_CREATE_TO2_LIST_ERROR = 'LOCATEINV_CREATE_TO2_LIST_ERROR';
export const LOCATEINV_ISSUE_TO2_START = 'LOCATEINV_ISSUE_TO2_START';
export const LOCATEINV_ISSUE_TO2_SUCCESS = 'LOCATEINV_ISSUE_TO2_SUCCESS';
export const LOCATEINV_ISSUE_TO2_ERROR = 'LOCATEINV_ISSUE_TO2_ERROR';
export const LOCATEINV_CREATE_TO3_START = 'LOCATEINV_CREATE_TO3_START';
export const LOCATEINV_CREATE_TO3_SUCCESS = 'LOCATEINV_CREATE_TO3_SUCCESS';
export const LOCATEINV_CREATE_TO3_ERROR = 'LOCATEINV_CREATE_TO3_ERROR';
export const LOCATEINV_CREATE_TO3_LIST_START = 'LOCATEINV_CREATE_TO3_LIST_START';
export const LOCATEINV_CREATE_TO3_LIST_SUCCESS = 'LOCATEINV_CREATE_TO3_LIST_SUCCESS';
export const LOCATEINV_CREATE_TO3_LIST_ERROR = 'LOCATEINV_CREATE_TO3_LIST_ERROR';
export const LOCATEINV_ISSUE_TO3_START = 'LOCATEINV_ISSUE_TO3_START';
export const LOCATEINV_ISSUE_TO3_SUCCESS = 'LOCATEINV_ISSUE_TO3_SUCCESS';
export const LOCATEINV_ISSUE_TO3_ERROR = 'LOCATEINV_ISSUE_TO3_ERROR';

export const GOOGLE_AUTHENTICATION_INIT = 'GOOGLE_AUTHENTICATION_INIT';
export const GOOGLE_AUTHENTICATION_START = 'GOOGLE_AUTHENTICATION_START';
export const GOOGLE_AUTHENTICATION_SUCCESS = 'GOOGLE_AUTHENTICATION_SUCCESS';
export const GOOGLE_PROFILE_LOADED = 'GOOGLE_PROFILE_LOADED';
export const GOOGLE_AUTHENTICATION_TOKEN_EXPIRE = 'GOOGLE_AUTHENTICATION_TOKEN_EXPIRE';
export const GOOGLE_CLEAN_CACHED_TOKEN = 'GOOGLE_CLEAN_CACHED_TOKEN';
export const GOOGLE_LOGIN_FETCH_USER_INIT = 'GOOGLE_LOGIN_FETCH_USER_INIT';
export const GOOGLE_AUTHENTICATION_DOMAIN_ACCESS_DENIED = 'GOOGLE_AUTHENTICATION_DOMAIN_ACCESS_DENIED';

export const LOCATEINV_FETCH_VENDORS_INIT = 'LOCATEINV_FETCH_VENDORS_INIT';
export const LOCATEINV_FETCH_VENDORS_START = 'LOCATEINV_FETCH_VENDORS_START';
export const LOCATEINV_FETCH_VENDORS_SUCCESS = 'LOCATEINV_FETCH_VENDORS_SUCCESS';
export const LOCATEINV_FETCH_VENDORS_ERROR = 'LOCATEINV_FETCH_VENDORS_ERROR';

export const XERO_PROJECT_CREATE_START = 'XERO_PROJECT_CREATE_START';
export const XERO_PROJECT_CREATE_ERROR = 'XERO_PROJECT_CREATE_ERROR';
export const XERO_PROJECT_CREATE_SUCCESS = 'XERO_PROJECT_CREATE_SUCCESS';
export const XERO_PROJECT_CREATE_MESSAGE = 'XERO_PROJECT_CREATE_MESSAGE';
export const XERO_PROJECT_CREATE_INITIALIZE = 'XERO_PROJECT_CREATE_INITIALIZE';
export const XERO_PROJECT_CREATE_START_LOADING = 'XERO_PROJECT_CREATE_START_LOADING';

export const XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES = 'XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES';
export const XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_STRAT = 'XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_START';
export const XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_SUCCESS = 'XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_SUCCESS';
export const XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_ERROR = 'XERO_PROJECT_FETCH_ALL_TRACKING_CATEGORIES_ERROR';

export const LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA = 'LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA';
export const LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_START = 'LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_START';
export const LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_SUCCESS = 'LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_SUCCESS';
export const LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_ERROR = 'LOCATEINV_FETCH_ENTERPRISE_PROJECT_DATA_ERROR';

export const LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_INIT = 'LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_INIT';
export const LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_START = 'LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_START';
export const LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_SUCCESS = 'LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_SUCCESS';
export const LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_ERROR = 'LOCATEINV_CREATE_VENDOR_SITE_AND_LINK_TO_TC_ERROR';

export const LOCATEINV_LINK_VENDOR_SITE_WITH_TC_INIT = 'LOCATEINV_LINK_VENDOR_SITE_WITH_TC_INIT';
export const LOCATEINV_LINK_VENDOR_SITE_WITH_TC_START = 'LOCATEINV_LINK_VENDOR_SITE_WITH_TC_START';
export const LOCATEINV_LINK_VENDOR_SITE_WITH_TC_SUCCESS = 'LOCATEINV_LINK_VENDOR_SITE_WITH_TC_SUCCESS';
export const LOCATEINV_LINK_VENDOR_SITE_WITH_TC_ERROR = 'LOCATEINV_LINK_VENDOR_SITE_WITH_TC_ERROR';

export const OPTIONS_GET_BY_NAME_INIT = 'OPTIONS_GET_BY_NAME_INIT';
export const OPTIONS_GET_BY_NAME_START = 'OPTIONS_GET_BY_NAME_START';
export const OPTIONS_GET_BY_NAME_SUCCESS = 'OPTIONS_GET_BY_NAME_SUCCESS';
export const OPTIONS_GET_BY_NAME_ERROR = 'OPTIONS_GET_BY_NAME_ERROR';

export const OPTIONS_OPTION_SET_INIT = 'OPTIONS_OPTION_SET_INIT';
export const OPTIONS_OPTION_SET_START = 'OPTIONS_OPTION_SET_START';
export const OPTIONS_OPTION_SET_SUCCESS = 'OPTIONS_OPTION_SET_SUCCESS';
export const OPTIONS_OPTION_SET_ERROR = 'OPTIONS_OPTION_SET_ERROR';


// Dropship orders start and end

export const LOCATEINV_FETCH_DROPSHIP_PO_INIT = 'LOCATEINV_FETCH_DROPSHIP_PO_INIT';
export const LOCATEINV_FETCH_DROPSHIP_PO_START = 'LOCATEINV_FETCH_DROPSHIP_PO_START';
export const LOCATEINV_FETCH_DROPSHIP_PO_SUCCESS = 'LOCATEINV_FETCH_DROPSHIP_PO_SUCCESS';
export const LOCATEINV_FETCH_DROPSHIP_PO_ERROR = 'LOCATEINV_FETCH_DROPSHIP_PO_ERROR';

// Dropship orders Confirm

export const LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_INIT = 'LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_INIT';
export const LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_START = 'LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_START';
export const LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_SUCCESS = 'LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_SUCCESS';
export const LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_ERROR = 'LOCATEINV_FETCH_DROPSHIP_PO_CONFIRM_ERROR';
